import {AppMode} from "../app-mode.model";
import {Employer} from "../employment-relationship.model";
import {FirebaseEntity} from "../firebase-entity.model";
import {Language} from "../language.model";
import {Summary} from "../summary.type";
import {TermsAgreement} from "../terms-agreement.model";

export interface UserProfile extends FirebaseEntity {
	appMode: AppMode;
	currentMerchant?: Employer;
	displayName?: string;
	email?: string;
	language: Language;
	partnerMode: string;
	phoneNumber?: string;
	referringPartner: string;
	termsAgreements?: TermsAgreement[];
}

export type UserSummary = Summary<UserProfile, "displayName">;

export function SummaryFromUserProfile(user: UserProfile): UserSummary {
	return {
		//tslint:disable:no-unnecessary-type-assertion
		displayName: user.displayName!,
		key: user.$key!,
	};
}
