import {Action} from "@ngrx/store";
import {isType} from "ts-action";
import {
	Employee,
	EmploymentRelationship,
} from "../../../../../../../lib/model/employment-relationship.model";
import {MerchantLocation} from "../../../../../../../lib/model/merchant-location.model";
import {Merchant} from "../../../../../../../lib/model/merchant.model";
import {Offer} from "../../../../../../../lib/model/offer.model";
import {RewardDetails} from "../../../../../../../lib/model/reward.model";
import {UserInvitation} from "../../../../core/user/user-invitation.model";
import {CurrentMerchantActions} from "./current-merchant.actions";

export interface CurrentMerchantState {
	currentMerchantId?: string;
	employees: Employee[];
	employmentRelationship?: EmploymentRelationship;
	merchant?: Merchant;
	merchantLocations: MerchantLocation[];
	offers: Offer[];
	recentValidations: RewardDetails[];
	userInvitations: UserInvitation[];
}

const defaultState: CurrentMerchantState = {
	employees: [],
	merchantLocations: [],
	offers: [],
	recentValidations: [],
	userInvitations: [],
};

export function merchantReducer(
	state: CurrentMerchantState = defaultState,
	action: Action
): CurrentMerchantState {
	if (isType(action, CurrentMerchantActions.QueryMerchant)) {
		return {...state, currentMerchantId: action.payload};
	}
	if (isType(action, CurrentMerchantActions.CurrentMerchantReceived)) {
		return {...state, merchant: action.payload};
	}
	if (isType(action, CurrentMerchantActions.LocationsReceived)) {
		return {...state, merchantLocations: action.payload};
	}
	if (isType(action, CurrentMerchantActions.OffersReceived)) {
		return {...state, offers: action.payload};
	}
	if (isType(action, CurrentMerchantActions.UserInvitationsReceived)) {
		return {...state, userInvitations: action.payload};
	}
	if (isType(action, CurrentMerchantActions.UsersReceived)) {
		return {...state, employees: action.payload};
	}
	if (isType(action, CurrentMerchantActions.RecentValidationsReceived)) {
		return {...state, recentValidations: action.payload};
	}
	return state;
}

export const getCurrentMerchant = (state: CurrentMerchantState) =>
	state.merchant;
export const getCurrentUserMerchant = (state: CurrentMerchantState) =>
	state.employmentRelationship;
export const getCurrentMerchantId = (state: CurrentMerchantState) =>
	state.currentMerchantId;
export const getMerchantLocations = (state: CurrentMerchantState) =>
	state.merchantLocations;
export const getMerchantOffers = (state: CurrentMerchantState) => state.offers;
export const getMerchantUserInvitations = (state: CurrentMerchantState) =>
	state.userInvitations;
export const getMerchantEmployees = (state: CurrentMerchantState) =>
	state.employees;
export const getMerchantRecentValidations = (state: CurrentMerchantState) =>
	state.recentValidations;
