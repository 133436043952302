import {Injectable} from "@angular/core";
import {Observable} from "rxjs";
import {Gateway} from "../../../../../lib/model/gateway.model";
import {FirestoreService} from "../firebase/firestore.service";

@Injectable()
export class GatewaysService {
	constructor(private firestore: FirestoreService) {}

	getGateways(): Observable<Gateway[]> {
		return this.firestore.toListStream(this.firestore.getGateways());
	}
}
