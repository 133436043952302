import {Component} from "@angular/core";
import {ModalController, NavController, NavParams} from "@ionic/angular";
import {Store} from "@ngrx/store";
import {Observable} from "rxjs";
import {map} from "rxjs/operators";
import {AppActions} from "../../../+state/app.actions";
import {AppMode} from "../../../../../../lib/model/app-mode.model";
import {Employer} from "../../../../../../lib/model/employment-relationship.model";
import {MerchantsService} from "../../../core/merchants/merchants.service";
import {
	JoinMerchantScanRequestParams,
	JoinMerchantScanRequestResponse,
} from "../../../core/scan-request/scan-request-params.model";
import {ScanRequestAction} from "../../../core/scan-request/scan-request.model";
import {MovebeState} from "../../../movebe-state.model";
import {filterNulls} from "../../rxjs-operators/filter-nulls";
import * as fromUser from "../../user/+state";
import {UserActions} from "../../user/+state/user.actions";

@Component({
	selector: "page-mm-join-existing-merchant",
	styleUrls: ["join-existing-merchant.modal.scss"],
	templateUrl: "join-existing-merchant.modal.html",
})
export class JoinExistingMerchantModal {
	ScanRequestAction = ScanRequestAction;

	scanRequestParams$: Observable<JoinMerchantScanRequestParams>;
	employer: Employer;

	constructor(
		private modalController: ModalController,
		private navParams: NavParams,
		private navCtrl: NavController,
		private merchantsService: MerchantsService,
		private store: Store<MovebeState>
	) {
		this.scanRequestParams$ = this.store.select(fromUser.getUserProfile).pipe(
			filterNulls(),
			map(user => ({
				email: user.email!,
				name: user.displayName!,
				phone: user.phoneNumber!,
			}))
		);
	}

	goToMerchant() {
		this.store.dispatch(UserActions.SelectCurrentMerchant(this.employer));
		this.store.dispatch(AppActions.SelectAppMode(AppMode.merchant));
		return this.dismiss();
	}

	requestApproved(response: JoinMerchantScanRequestResponse) {
		this.employer = response.employer;
	}

	dismiss() {
		return this.modalController.dismiss();
	}
}
