import {Injectable} from "@angular/core";
import {Observable} from "rxjs";
import {switchMap} from "rxjs/operators";
import {Provider} from "../../../../../lib/model/provider.model";
import {FirestoreService} from "../firebase/firestore.service";
import {Logger} from "../logger/logger.service";
import {MovebeApiService} from "../movebe-api/movebe-api.service";

@Injectable()
export class ProvidersService {
	constructor(
		private firestore: FirestoreService,
		private logger: Logger,
		private movebeApiService: MovebeApiService
	) {}

	add(newProvider: Provider): Observable<Provider | null> {
		this.logger.info(`adding provider ${newProvider.name}`);
		return this.movebeApiService
			.putProvider(newProvider)
			.pipe(switchMap((merchantId: string) => this.get(merchantId)));
	}

	get(providerId: string): Observable<Provider | null> {
		return this.firestore.toObjectStream(
			this.firestore.getProvider(providerId)
		);
	}

	getProviders(): Observable<Provider[]> {
		return this.firestore.toListStream(this.firestore.getProviders());
	}
}
