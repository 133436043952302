import {Injectable} from "@angular/core";
import {Store} from "@ngrx/store";
import * as moment from "moment/moment";
import {Observable} from "rxjs";
import {combineLatest} from "rxjs/internal/observable/combineLatest";
import {of} from "rxjs/internal/observable/of";
import {first, map, switchMap} from "rxjs/operators";
import {
	Benefit,
	BenefitType,
	Coupon,
} from "../../../../../lib/model/benefit.model";
import {Currency} from "../../../../../lib/model/country.model";
import {
	Merchant,
	SummaryFromMerchant,
} from "../../../../../lib/model/merchant.model";
import {OfferDetails} from "../../../../../lib/model/offer-details.model";
import {Offer, OfferStatus} from "../../../../../lib/model/offer.model";
import {
	IssuedReward,
	PendingReward,
	RewardStatus,
} from "../../../../../lib/model/reward.model";
import {Timestamp} from "../../../../../lib/model/timestamp.model";
import {filterNulls} from "../../lib/rxjs-operators/filter-nulls";
import * as fromUser from "../../lib/user/+state/index";
import {MovebeState} from "../../movebe-state.model";
import {CouponService} from "../coupon/coupon.service";
import {FirebaseService} from "../firebase/firebase.service";
import {FirestoreService} from "../firebase/firestore.service";
import {Logger} from "../logger/logger.service";
import {ParkingValidationService} from "../parking/parking-validation.service";
import {RewardsService} from "../rewards/rewards.service";
import {OfferScanRequestParams} from "../scan-request/scan-request-params.model";
import {ScanRequest} from "../scan-request/scan-request.model";

@Injectable()
export class OffersService {
	private userId$: Observable<string>;

	constructor(
		private couponService: CouponService,
		private fb: FirebaseService,
		private firestore: FirestoreService,
		private logger: Logger,
		private parkingValidationService: ParkingValidationService,
		private rewardsService: RewardsService,
		private store: Store<MovebeState>
	) {
		this.userId$ = this.store.select(fromUser.getUserId).pipe(filterNulls());
	}

	addBenefit(benefit: Benefit, key: string): Observable<Benefit | null> {
		const newBenefit = this.firestore.getBenefit(key);
		newBenefit.set(benefit);
		return this.firestore.toObjectStream(newBenefit);
	}

	//TODO: implement consistent return type for add methods the return generated key for new item as well as observable of added item
	addCoupon(merchantId: string, coupon: Coupon): Promise<string> {
		const couponId = this.fb.generateId(`${coupon.title}`);
		const newVoucher = this.firestore.getMerchantCoupon(merchantId, couponId);
		return newVoucher.set(coupon).then(() => couponId);
	}

	updateCoupon(merchantId: string, coupon: Coupon): Promise<void> {
		const newVoucher = this.firestore.getMerchantCoupon(
			merchantId,
			coupon.$key!
		);
		return newVoucher.update(coupon);
	}

	addOffer(merchantId: string, offer: Offer): Observable<Offer | null> {
		const offerId = this.fb.generateId(
			`${offer.criteria} ${offer.benefit.key}`
		);
		const newOffer = this.firestore.getMerchantOffer(merchantId, offerId);
		newOffer.set(offer);
		return this.firestore.toObjectStream(newOffer);
	}

	getBenefit(benefitId: string): Observable<Benefit | null> {
		return this.firestore.toObjectStream(this.firestore.getBenefit(benefitId));
	}

	getBenefits(currency: Currency): Observable<Benefit[]> {
		return this.firestore.toListStream(
			this.firestore.getBenefits(ref => ref.where("currency", "==", currency))
		);
	}

	getCoupons(merchantId: string): Observable<Coupon[]> {
		return this.firestore.toListStream(
			this.firestore.getMerchantCoupons(merchantId)
		);
	}

	getCoupon(merchantId: string, couponId: string): Observable<Coupon | null> {
		return this.firestore.toObjectStream(
			this.firestore.getMerchantCoupon(merchantId, couponId)
		);
	}

	getNewOffer(): Offer {
		return {
			benefit: {
				description: "",
				key: "",
				type: null,
			},
			criteria: "",
			expires: {hours: 6},
			requiredValidations: 1,
			sortPosition: -1,
			status: OfferStatus.new,
			universal: true,
		};
	}

	getOffer(merchantId: string, offerId: string): Observable<Offer | null> {
		return this.firestore.toObjectStream(
			this.firestore.getMerchantOffer(merchantId, offerId)
		);
	}

	getOfferDetails(
		merchantId: string,
		locationId: string,
		offerId: string
	): Observable<OfferDetails | null> {
		return this.firestore
			.toObjectStream(this.firestore.getMerchantOffer(merchantId, offerId))
			.pipe(
				switchMap(offer => {
					return offer
						? ((offer.benefit.type === BenefitType.coupon
								? this.getCoupon(merchantId, offer.benefit.key)
								: this.getBenefit(offer.benefit.key)) as Observable<
								Benefit
						  >).pipe(
								map(benefit => {
									return {offer, benefit};
								})
						  )
						: of(null);
				})
			);
	}

	getOffers(merchantId: string): Observable<Offer[]> {
		return this.firestore.toListStream(
			this.firestore.getMerchantOffers(merchantId)
		);
	}

	getVisibleOffers(merchantId: string): Observable<Offer[]> {
		return this.firestore
			.toListStream(this.firestore.getMerchantOffers(merchantId))
			.pipe(
				map(offers =>
					offers.filter(
						offer =>
							offer.status === OfferStatus.activated &&
							(!offer.beginDate || moment().isAfter(offer.beginDate)) &&
							(!offer.endDate || moment().isBefore(offer.endDate))
					)
				)
			);
	}

	updateOffer(merchantId: string, offer: Offer) {
		return this.firestore
			.getMerchantOffer(merchantId, offer.$key!)
			.update(offer);
	}

	deleteOffer(merchantId: string, offer: Offer) {
		if (offer.status !== OfferStatus.new) {
			throw new Error(`"Cannot delete offer with status "${offer.status}"`);
		}
		return this.firestore.getMerchantOffer(merchantId, offer.$key!).delete();
	}

	async validateOffer(
		scanRequest: ScanRequest,
		merchant: Merchant
	): Promise<PendingReward | IssuedReward> {
		const requestParams = scanRequest.requestParams as OfferScanRequestParams;
		const [userId, offerDetails] = await combineLatest(
			this.userId$,
			this.getOfferDetails(
				requestParams.merchantId,
				requestParams.locationId!,
				requestParams.offerId
			).pipe(filterNulls())
		)
			.pipe(first())
			.toPromise();

		const pendingReward = this.generatePendingRewardWithValidation(
			scanRequest,
			userId,
			merchant,
			offerDetails
		);

		if (
			pendingReward.validations.length !== pendingReward.requiredValidations
		) {
			return pendingReward;
		}

		const issuedReward = await this.allocateReward(pendingReward, offerDetails)
			.then(reward => this.firestore.getRewards().add(reward))
			.then(
				ref =>
					this.firestore
						.toObjectStream(this.firestore.getReward(ref.id))
						.pipe(
							filterNulls(),
							first()
						)
						.toPromise() as Promise<IssuedReward>
			);

		const benefit = offerDetails.offer.benefit;
		if (benefit.type !== BenefitType.coupon) {
			return this.rewardsService
				.insertAllocation(issuedReward)
				.then(() => issuedReward);
		} else {
			return issuedReward;
		}
	}

	generatePendingRewardWithValidation(
		scanRequest: ScanRequest,
		agentId: string,
		merchant: Merchant,
		offerDetails: OfferDetails
	): PendingReward {
		const requestParams = scanRequest.requestParams as OfferScanRequestParams;
		return {
			benefit: offerDetails.offer.benefit,
			consumerId: scanRequest.requestedByUserId,
			createdTimestamp: this.firestore.serverTimestamp(),
			merchant: SummaryFromMerchant(merchant),
			offerId: offerDetails.offer.$key!,
			requiredValidations: offerDetails.offer.requiredValidations,
			status: RewardStatus.pending,
			surveyRequired: true,
			validations: [
				{
					agentId,
					locationId: requestParams.locationId!,
					timestamp: (new Date() as any) as Timestamp,
				},
			],
		};
	}

	async allocateReward(
		pendingReward: PendingReward,
		offerDetails: OfferDetails
	): Promise<IssuedReward> {
		return {
			...pendingReward,
			expires: (moment()
				.add(moment.duration(offerDetails.offer.expires))
				.toDate() as any) as Timestamp,
			isCurrent: true,
			issuedTimestamp: this.firestore.serverTimestamp(),
			status: RewardStatus.allocated,
			surveyCompleted: false,
			...(pendingReward.benefit.type === BenefitType.coupon
				? {
						voucher: this.couponService.getCouponVoucher(
							pendingReward,
							offerDetails
						),
				  }
				: pendingReward.benefit.type === BenefitType.parking
					? {
							voucher: await this.parkingValidationService.getParkingVoucher(
								pendingReward,
								offerDetails
							),
					  }
					: {}),
		};
	}
}
