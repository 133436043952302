import {Component, OnDestroy} from "@angular/core";
import {ModalController} from "@ionic/angular";
import {select, Store} from "@ngrx/store";
import {TranslateService} from "@ngx-translate/core";
import {Subject} from "rxjs";
import {Observable} from "rxjs";
import {filter, takeUntil} from "rxjs/operators";
import * as fromUser from "../+state/index";
import {UserActions} from "../+state/user.actions";
import {UserProfile} from "../../../../../../lib/model/user/user-profile.model";
import {BusyService} from "../../../core/busy/busy.service";
import {Logger} from "../../../core/logger/logger.service";
import {CurrentUserService} from "../../../core/user/current-user.service";
import {MovebeState} from "../../../movebe-state.model";

@Component({
	selector: "page-mb-verify-email-modal",
	templateUrl: "./verify-email.modal.html",
})
export class VerifyEmailModal implements OnDestroy {
	private done$ = new Subject<void>();
	userProfile$: Observable<UserProfile>;

	verificationEmailResent = false;

	constructor(
		private busyService: BusyService,
		private currentUserService: CurrentUserService,
		private store: Store<MovebeState>,
		private translate: TranslateService,
		private modalController: ModalController,
		private logger: Logger
	) {
		this.store
			.pipe(
				select(fromUser.getIsUserEmailVerified),
				filter(isVerified => !!isVerified),
				takeUntil(this.done$)
			)
			.subscribe(() => this.dismiss(true));

		this.userProfile$ = this.store.select(fromUser.getUserProfile);
	}

	refreshAuth() {
		this.store.dispatch(UserActions.ReloadAuthState());
	}

	resendVerificationEmail(): void {
		this.verificationEmailResent = false;
		const emailSentPromise = this.currentUserService
			.sendEmailVerification()
			.then(() => (this.verificationEmailResent = true));
		this.busyService.setBusy(
			emailSentPromise,
			this.translate.instant("AUTH.SENDING_VERIFICATION_EMAIL")
		);
	}

	dismiss(emailVerified: boolean) {
		return this.modalController.dismiss(emailVerified);
	}

	ngOnDestroy() {
		this.done$.next();
		this.done$.complete();
	}
}
