import {
	HTTP_INTERCEPTORS,
	HttpClient,
	HttpClientModule,
} from "@angular/common/http";
import {ErrorHandler, NgModule} from "@angular/core";
import {FlexLayoutModule} from "@angular/flex-layout";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {BrowserModule} from "@angular/platform-browser";
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import {RouteReuseStrategy} from "@angular/router";
import {SplashScreen} from "@ionic-native/splash-screen/ngx";
import {StatusBar} from "@ionic-native/status-bar/ngx";
import {IonicModule, IonicRouteStrategy} from "@ionic/angular";
import {IonicStorageModule} from "@ionic/storage";
import {EffectsModule} from "@ngrx/effects";
import {StoreModule} from "@ngrx/store";
import {StoreDevtoolsModule} from "@ngrx/store-devtools";
import {TranslateLoader, TranslateModule} from "@ngx-translate/core";
import {TranslateHttpLoader} from "@ngx-translate/http-loader";
import * as fromApp from "./+state";
import {AppRoutingModule} from "./app-routing.module";
import {AppComponent} from "./app.component";
import {MovebeCoreModule} from "./core/core.module";
import {ErrorHandlerService} from "./core/error-handler/error-handler.service";
import {MovebeApiInterceptor} from "./core/movebe-api/movebe-api.interceptor";
import {ChatModule} from "./lib/chat/chat.module";
import {MerchantMembershipModule} from "./lib/merchant-membership/merchant-membership.module";
import {UserModule} from "./lib/user/user.module";
import {MovebeSharedModule} from "./shared/movebe-shared.module";

@NgModule({
	declarations: [AppComponent],
	entryComponents: [],
	imports: [
		//These NgRx modules must be imported in this order and before any feature modules are imported
		// (see https://github.com/ngrx/platform/issues/624)
		StoreModule.forRoot({}),
		EffectsModule.forRoot([]),
		StoreDevtoolsModule.instrument({
			maxAge: 25,
		}),
		StoreModule.forFeature(fromApp.featureName, fromApp.reducer),
		EffectsModule.forFeature(fromApp.effects),
		//----------------------------------------
		BrowserAnimationsModule,
		BrowserModule,
		ChatModule,
		FlexLayoutModule,
		FormsModule,
		HttpClientModule,
		IonicModule.forRoot(),
		IonicStorageModule.forRoot(),

		MovebeCoreModule,
		MovebeSharedModule,
		ReactiveFormsModule,
		UserModule,
		MerchantMembershipModule,
		TranslateModule.forRoot({
			loader: {
				deps: [HttpClient],
				provide: TranslateLoader,
				useFactory: createTranslateLoader,
			},
		}),
		AppRoutingModule,
	],
	providers: [
		StatusBar,
		SplashScreen,
		{provide: RouteReuseStrategy, useClass: IonicRouteStrategy},
		ErrorHandlerService,
		{
			provide: ErrorHandler,
			useExisting: ErrorHandlerService,
		},
		{
			multi: true,
			provide: HTTP_INTERCEPTORS,
			useClass: MovebeApiInterceptor,
		},
	],
	bootstrap: [AppComponent],
})
export class AppModule {}

export function createTranslateLoader(http: HttpClient) {
	return new TranslateHttpLoader(http, "./assets/i18n/", ".json");
}
