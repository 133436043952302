import {ChangeDetectorRef, NgZone, Pipe, PipeTransform} from "@angular/core";
import {Moment} from "moment";
import {TimeAgoPipe as NgxMomentTimeAgoPipe} from "ngx-moment";
import {isTimestamp, Timestamp} from "../../../../../lib/model/timestamp.model";

/**
 * Custom wrapper for ngx-moment TimeAgoPipe that also accepts Firestore Timestamp objects
 */
@Pipe({name: "movebeTimeAgo"})
export class TimeAgoPipe implements PipeTransform {
	constructor(private cdRef: ChangeDetectorRef, private ngZone: NgZone) {}

	transform(value: Date | Moment | Timestamp, omitSuffix?: boolean): string {
		return new NgxMomentTimeAgoPipe(this.cdRef, this.ngZone).transform(
			isTimestamp(value) ? value.toDate() : value,
			omitSuffix
		);
	}
}
