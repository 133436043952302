import {ChangeDetectorRef, NgZone, Pipe, PipeTransform} from "@angular/core";
import {Moment} from "moment";
import {CalendarPipe as NgxMomentCalendarPipe} from "ngx-moment";
import {isTimestamp, Timestamp} from "../../../../../lib/model/timestamp.model";

/**
 * Custom wrapper for ngx-moment CalendarPipe that also accepts Firestore Timestamp objects
 */
@Pipe({name: "movebeCalendar"})
export class CalendarPipe implements PipeTransform {
	constructor(private cdRef: ChangeDetectorRef, private ngZone: NgZone) {}

	transform(value: Date | Moment | Timestamp, ...args: any[]): string {
		return new NgxMomentCalendarPipe(this.cdRef, this.ngZone).transform(
			isTimestamp(value) ? value.toDate() : value,
			...args
		);
	}
}
