import {Component, EventEmitter, Output} from "@angular/core";
import {Store} from "@ngrx/store";
import {Observable} from "rxjs";
import {first} from "rxjs/operators";
import * as fromUser from "../+state";
import {AppActions} from "../../../+state/app.actions";
import * as fromApp from "../../../+state/index";
import {AppMode} from "../../../../../../lib/model/app-mode.model";
import {Employer} from "../../../../../../lib/model/employment-relationship.model";
import {UserProfile} from "../../../../../../lib/model/user/user-profile.model";
import {MovebeState} from "../../../movebe-state.model";

@Component({
	selector: "movebe-app-mode-chooser",
	styleUrls: ["./app-mode-chooser.component.scss"],
	templateUrl: "./app-mode-chooser.component.html",
})
export class AppModeChooserComponent {
	AppMode = AppMode;
	appMode$: Observable<AppMode>;
	employers$: Observable<Employer[]>;
	@Output()
	appModeChanged = new EventEmitter<AppMode>();
	userProfile$: Observable<UserProfile | null>;

	constructor(private store: Store<MovebeState>) {
		this.appMode$ = this.store.select(fromApp.getAppMode);
		this.userProfile$ = this.store.select(fromUser.getUserProfile);
		this.employers$ = this.store.select(fromApp.getEmployers);
	}

	appModeSelectionChanged(appMode: AppMode) {
		this.employers$.pipe(first()).subscribe(employers => {
			if (appMode !== AppMode.merchant || (employers && employers.length > 0)) {
				this.store.dispatch(AppActions.SelectAppMode(appMode));
			}
			this.appModeChanged.emit(appMode);
		});
	}
}
