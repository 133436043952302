import {HttpErrorResponse} from "@angular/common/http";
import {Action} from "@ngrx/store";
import {isType} from "ts-action";
import {AppMode} from "../../../../lib/model/app-mode.model";
import {BusyState} from "../../../../lib/model/busy-state.model";
import {Employer} from "../../../../lib/model/employment-relationship.model";
import {AppActions} from "./app.actions";

export interface AppState {
	appMode: AppMode;
	busyState?: BusyState;
	httpError?: HttpErrorResponse;
	employers: Employer[];
}

const defaultState: AppState = {
	appMode: AppMode.init,
	employers: [],
};

export function appReducer(
	state: AppState = defaultState,
	action: Action
): AppState {
	if (isType(action, AppActions.SelectAppMode)) {
		return {...state, appMode: action.payload};
	}
	if (isType(action, AppActions.HttpErrorOccurred)) {
		return {...state, httpError: action.payload};
	}
	if (isType(action, AppActions.EmployersReceived)) {
		return {...state, employers: action.payload};
	}
	return state;
}

export const getAppMode = (state: AppState) => state.appMode;
export const getBusyState = (state: AppState) => state.busyState;
export const getEmployers = (state: AppState) => state.employers;
