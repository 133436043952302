import {
	ChangeDetectionStrategy,
	Component,
	ElementRef,
	EventEmitter,
	Input,
	NgZone,
	OnChanges,
	OnInit,
	Output,
	ViewChild,
} from "@angular/core";
import {FormControl} from "@angular/forms";
import {Country} from "../../../../../lib/model/country.model";
import {LatLngLiteral} from "../../../../../lib/model/mapping/lat-lng-literal.model";
import {SelectableList} from "../../../../../lib/model/selectable-list.model";
import {Logger} from "../../core/logger/logger.service";
import {MappingService} from "../../core/mapping/mapping.service";
import {MapMarkerState, MapMarkerType} from "../map/map-marker-type.model";
import {MapMarker} from "../map/map-marker.class";
import {movebeMapStyles} from "../map/map-styles.data";
import PlaceResult = google.maps.places.PlaceResult;

@Component({
	changeDetection: ChangeDetectionStrategy.OnPush,
	selector: "movebe-location-finder",
	styleUrls: ["./location-finder.component.scss"],
	templateUrl: "./location-finder.component.html",
})
export class LocationFinderComponent implements OnInit, OnChanges {
	@Input()
	country: Country;
	@Input()
	initialLatLng: LatLngLiteral;
	@Input()
	selectablePlaces: SelectableList<PlaceResult> = [];
	@Output()
	onMapChanged = new EventEmitter<google.maps.places.PlaceResult | null>();
	@ViewChild("search", {read: ElementRef})
	searchElementRef: ElementRef;

	centerMarker: google.maps.Marker;
	map: google.maps.Map;
	mapOptions: google.maps.MapOptions;
	markerPosition: LatLngLiteral;
	searchControl: FormControl;
	zoom = 14;
	mapCenterMarker: MapMarker;
	selectedLocations: MapMarker[];
	searchTerm: string;
	readonly mapReady: Promise<google.maps.Map>;
	private mapResolve: (
		value?: PromiseLike<google.maps.Map> | google.maps.Map
	) => void;

	constructor(
		private ngZone: NgZone,
		private mappingService: MappingService,
		private logger: Logger
	) {
		this.mapReady = new Promise<google.maps.Map>(resolve => {
			this.mapResolve = resolve;
		});
	}

	ngOnInit() {
		this.searchControl = new FormControl();
		this.mapOptions = {
			center: this.initialLatLng,
			disableDefaultUI: true,
			gestureHandling: "none",
			styles: movebeMapStyles,
			zoom: this.zoom,
		};
		this.mapCenterMarker = new MapMarker(
			this.initialLatLng,
			MapMarkerType.position
		);
	}

	ngOnChanges() {
		if (this.selectablePlaces) {
			this.selectedLocations = this.selectablePlaces.map(place => {
				const markerState = place.disabled
					? MapMarkerState.disabled
					: place.selected
						? MapMarkerState.highlight
						: MapMarkerState.normal;
				return new MapMarker(
					place.item.geometry.location,
					MapMarkerType.merchant,
					markerState
				);
			});
		}

		this.mapReady.then(map => {
			if (this.selectedLocations.length > 0) {
				const bounds = new google.maps.LatLngBounds();
				bounds.extend(this.centerMarker.getPosition());
				this.selectedLocations.forEach(loc => bounds.extend(loc.position));
				this.map.fitBounds(bounds);
			}
		});
	}

	onMapReady(map: google.maps.Map) {
		this.map = map;
		this.mapResolve(map);
		const inputElement: HTMLInputElement = (this.searchElementRef
			.nativeElement as HTMLElement).querySelector("input")!;
		const autocomplete = new google.maps.places.Autocomplete(inputElement, {
			componentRestrictions: {country: this.country},
		});
		autocomplete.bindTo("bounds", this.map);
		autocomplete.addListener("place_changed", () => {
			this.ngZone.run(() => {
				const place: google.maps.places.PlaceResult = autocomplete.getPlace();
				if (place.place_id) {
					this.searchTerm = place.formatted_address;
					const currentLatLng = this.mappingService.googleGeometryToLatLng(
						place.geometry
					);
					this.map.setCenter(currentLatLng);
					this.centerMarker.setPosition(currentLatLng);
					this.onMapChanged.emit(place);
				}
			});
		});
	}

	cancelSearch() {
		this.map.setCenter(this.initialLatLng);
		this.centerMarker.setPosition(this.initialLatLng);
		this.onMapChanged.emit(null);
	}

	onMarkerInit(marker: google.maps.Marker) {
		this.centerMarker = marker;
	}
}
