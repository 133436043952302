import {Injectable} from "@angular/core";
import {AngularFireAuth} from "@angular/fire/auth";
import {AlertController, LoadingController} from "@ionic/angular";
import {Actions, Effect} from "@ngrx/effects";
import {Action, select, Store} from "@ngrx/store";
import {TranslateService} from "@ngx-translate/core";
import {Observable} from "rxjs";
import {tap} from "rxjs/internal/operators/tap";
import {concatMap, map, switchMap} from "rxjs/operators";
import {ofType, toPayload} from "ts-action-operators";
import {Employer} from "../../../../lib/model/employment-relationship.model";
import {BusyService} from "../core/busy/busy.service";
import {FirebaseService} from "../core/firebase/firebase.service";
import {FirestoreService} from "../core/firebase/firestore.service";
import {Logger} from "../core/logger/logger.service";
import {filterNulls} from "../lib/rxjs-operators/filter-nulls";
import * as fromUser from "../lib/user/+state";
import {UserActions} from "../lib/user/+state/user.actions";
import {MovebeState} from "../movebe-state.model";
import {AppActions} from "./app.actions";

@Injectable()
export class AppEffects {
	@Effect()
	readonly selectAppModeEffect$ = this.actions$.pipe(
		ofType(AppActions.SelectAppMode),
		toPayload(),
		map(appMode => UserActions.SelectAppMode(appMode))
	);

	@Effect()
	readonly HttpErrorOccurredEffect$ = this.actions$.pipe(
		ofType(AppActions.HttpErrorOccurred),
		toPayload(),
		concatMap(httpErrorResponse => {
			this.logger.error(httpErrorResponse);
			let message = httpErrorResponse.message;
			//tslint:disable:no-unsafe-any
			const isSqlError =
				!!httpErrorResponse.error.message &&
				!!httpErrorResponse.error.message.sql;
			if (isSqlError) {
				message = `${message}<br><br>
										<strong>${this.translate.instant(
											"ERROR.SQL_ERROR_DETAILS"
										) as string}:</strong><br><br>
										${httpErrorResponse.error.message.name}<br><br>
										${httpErrorResponse.error.message.code}<br><br>
										${httpErrorResponse.error.message.sqlMessage}<br><br>
										${httpErrorResponse.error.message.sql}`;
			}
			//tslint:enable:no-unsafe-any
			return this.alertCtrl
				.create({
					buttons: [this.translate.instant("LABEL.OK") as string],
					header: this.translate.instant(
						"ERROR.AN_ERROR_HAS_OCCURRED"
					) as string,
					message,
					subHeader: `${httpErrorResponse.status}:  ${
						httpErrorResponse.statusText
					}`,
				})
				.then(alert => alert.present());
		}),
		map(() => AppActions.HttpErrorNotified())
	);

	readonly currentUserId$: Observable<string>;

	@Effect()
	readonly queryEmployersEffect$: Observable<Action>;

	constructor(
		private actions$: Actions,
		private afAuth: AngularFireAuth,
		private alertCtrl: AlertController,
		private busyService: BusyService,
		private fb: FirebaseService,
		private firestore: FirestoreService,
		public loadingCtrl: LoadingController,
		private store: Store<MovebeState>,
		private translate: TranslateService,
		private logger: Logger
	) {
		this.currentUserId$ = store
			.pipe(select(fromUser.getUserId))
			.pipe(filterNulls());

		this.queryEmployersEffect$ = this.actions$.pipe(
			ofType(AppActions.QueryEmployers),
			switchMap(() => this.currentUserId$),
			switchMap(userId => this.getUserEmployers(userId)),
			map(merchants => AppActions.EmployersReceived(merchants)),
		);
	}

	getUserEmployers(userId: string): Observable<Employer[]> {
		return this.firestore.toListStream(this.firestore.getUserEmployers(userId));
	}
}
