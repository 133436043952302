import {Component, Input, OnInit} from "@angular/core";
import {Observable} from "rxjs";
import {map} from "rxjs/operators";
import {MerchantLocation} from "../../../../../lib/model/merchant-location.model";
import {FloorPlan} from "../../core/movebe-markers/floor-plan.model";
import {FloorPlanPlacement} from "../../core/movebe-markers/marker-code-geotag.model";
import {MovebeMarkersService} from "../../core/movebe-markers/movebe-markers.service";

/**
 * MovebeMarkerMap component.
 * Displays a floor plan for MoveBeMarkers
 */
@Component({
	selector: "movebe-marker-map",
	styleUrls: ["movebe-marker-map.component.scss"],
	templateUrl: "movebe-marker-map.component.html",
})
export class MovebeMarkerMapComponent implements OnInit {
	readonly BLANK_IMAGE = "assets/img/1x1.transparent.png";
	@Input()
	floorPlanPlacement: FloorPlanPlacement;
	@Input()
	merchantLocation: MerchantLocation;
	@Input()
	size = 100;

	floorPlan$: Observable<FloorPlan | null>;
	imageUrl$: Observable<string | null>;

	constructor(private movebeMarkersService: MovebeMarkersService) {}

	ngOnInit(): void {
		this.floorPlan$ = this.movebeMarkersService.getFloorPlan(
			this.merchantLocation.merchantId,
			this.merchantLocation.$key!,
			this.floorPlanPlacement.floorPlanId
		);
		this.imageUrl$ = this.floorPlan$.pipe(
			map(
				floorPlan =>
					floorPlan ? `${floorPlan.url}=s${this.size}` : this.BLANK_IMAGE
			)
		);
	}

	getLeft() {
		/*tslint:disable:no-magic-numbers*/
		return `${this.floorPlanPlacement.position.x * 100}%`;
	}

	getTop() {
		return `${this.floorPlanPlacement.position.y * 100}%`;
	}
}
