import {Currency} from "./country.model";
import {BarCodeType} from "./coupon.model";
import {FirebaseEntity} from "./firebase-entity.model";
import {Summary} from "./summary.type";

export enum BenefitType {
	bikeShare = "bikeShare",
	coupon = "coupon",
	ferry = "ferry",
	fuel = "fuel",
	parking = "parking",
	points = "points",
	rideHail = "rideHail",
	toll = "toll",
	transit = "transit",
}

export type ProviderBenefitType =
	| BenefitType.bikeShare
	| BenefitType.ferry
	| BenefitType.fuel
	| BenefitType.parking
	| BenefitType.points
	| BenefitType.rideHail
	| BenefitType.toll
	| BenefitType.transit;

export interface BenefitBase extends FirebaseEntity {
	type: BenefitType;
	description: string;
}

export interface ProviderBenefit extends BenefitBase {
	type: ProviderBenefitType;
	amount: number;
	currency: Currency;
}

export interface Coupon extends BenefitBase {
	title: string;
	type: BenefitType.coupon;
	barCodeType: BarCodeType;
	barCodeValue: string;
}

export type Benefit = ProviderBenefit | Coupon;

export type BenefitSummary =
	| Summary<ProviderBenefit, "amount" | "currency" | "description" | "type">
	| Summary<Coupon, "description" | "type">;

export function SummaryFromBenefit(benefit: Benefit): BenefitSummary {
	return benefit.type === BenefitType.coupon
		? {
				description: benefit.description,
				key: benefit.$key!, //tslint:disable-line:no-unnecessary-type-assertion
				type: benefit.type,
		  }
		: {
				amount: benefit.amount,
				currency: benefit.currency,
				description: benefit.description,
				key: benefit.$key!, //tslint:disable-line:no-unnecessary-type-assertion
				type: benefit.type,
		  };
}
