/*tslint:disable:member-ordering */
import {Injectable} from "@angular/core";
import {mimeTypes} from "mime-wrapper";
import {Observable} from "rxjs";
import {combineLatest} from "rxjs/internal/observable/combineLatest";
import {switchMap} from "rxjs/internal/operators/switchMap";
import * as slug from "slug";
import {Currency} from "../../../../../lib/model/country.model";
import {
	Employee,
	EmploymentRelationship,
	MerchantRole,
	MerchantUserStatus,
} from "../../../../../lib/model/employment-relationship.model";
import {StakeholderBalances} from "../../../../../lib/model/merchant-balance.model";
import {MerchantLocation} from "../../../../../lib/model/merchant-location.model";
import {
	Merchant,
	MerchantStatus,
	MerchantSummary,
	SummaryFromMerchant,
	WebLink,
} from "../../../../../lib/model/merchant.model";
import {Offer} from "../../../../../lib/model/offer.model";
import {Provider} from "../../../../../lib/model/provider.model";
import {Reward, RewardDetails} from "../../../../../lib/model/reward.model";
import {UserSummary} from "../../../../../lib/model/user/user-profile.model";
import {childJoin} from "../../lib/rxjs-operators/child-join";
import {FirebaseService} from "../firebase/firebase.service";
import {FirestoreService} from "../firebase/firestore.service";
import {Logger} from "../logger/logger.service";
import {MappingService} from "../mapping/mapping.service";
import {MovebeApiService} from "../movebe-api/movebe-api.service";
import {RewardsService} from "../rewards/rewards.service";
import {
	UserInvitation,
	UserInvitationStatus,
} from "../user/user-invitation.model";

@Injectable()
export class MerchantsService {
	constructor(
		private fb: FirebaseService,
		private firestore: FirestoreService,
		private logger: Logger,
		private mappingService: MappingService,
		private movebeApiService: MovebeApiService,
		private rewardsService: RewardsService
	) {}

	addOrUpdateMerchant(merchant: Merchant) {
		return merchant.hasOwnProperty("id")
			? this.updateMerchant(merchant)
			: this.addMerchant(merchant);
	}

	addMerchant(newMerchant: Merchant): Observable<Merchant | null> {
		this.logger.info(`adding ${newMerchant.name}`);
		return this.movebeApiService
			.putMerchant(newMerchant)
			.pipe(switchMap((merchantId: string) => this.getMerchant(merchantId)));
	}

	updateMerchant(merchant: Merchant): Observable<Merchant | null> {
		const merchantAFO = this.firestore.getMerchant(merchant.$key!);
		merchantAFO.update(merchant);
		return this.firestore.toObjectStream(merchantAFO);
	}

	getNewMerchant(): Merchant {
		return {
			currency: Currency.CAD,
			description: "",
			homepage: "",
			name: "",
			nextLocation: 1,
			referringPartner: "movebe",
			status: MerchantStatus.pending,
			survey: "merchant-customer-satisfaction",
			telephone: "",
			type: "",
			webLinks: [],
		};
	}

	getNewMerchantLocation(merchant: Merchant): MerchantLocation {
		return {
			address: {
				country: "",
				municipality: "",
				postalCode: "",
				state: "",
				street: "",
			},
			hidden: false,
			isAddressMatchPlace: false,
			merchantId: merchant.$key!,
			name: merchant.name,
			openingHours: {
				days: [
					{closed: true},
					{closed: true},
					{closed: true},
					{closed: true},
					{closed: true},
					{closed: true},
					{closed: true},
				],
			},
			telephone: "",
			useGoogleMapsLocationData: false,
			vicinity: "",
		};
	}

	getNewWebLink(): WebLink {
		return {label: "", url: ""};
	}

	addLocation(locationData: MerchantLocation): Promise<string> {
		return this.movebeApiService.putLocation(locationData);
	}

	updateLocation(
		merchantLocation: MerchantLocation
	): Observable<MerchantLocation | null> {
		const locationAFO = this.firestore.getMerchantLocation(
			merchantLocation.merchantId,
			merchantLocation.$key!
		);
		locationAFO.update(merchantLocation);
		return this.firestore.toObjectStream(locationAFO);
	}

	getMerchant(merchantId: string): Observable<Merchant | null> {
		return this.firestore.toObjectStream(
			this.firestore.getMerchant(merchantId)
		);
	}

	getMerchantBalances(): Observable<StakeholderBalances[]> {
		return this.firestore.toListStream(this.firestore.getAccountBalances());
	}

	getMerchantBalance(
		merchantId: string
	): Observable<StakeholderBalances | null> {
		return this.firestore.toObjectStream(
			this.firestore.getMerchantBalance(merchantId)
		);
	}

	getEmployees(merchantId: string): Observable<Employee[]> {
		return this.firestore.toListStream(
			this.firestore.getMerchantEmployees(merchantId)
		);
	}

	getEmployee(merchantId: string, userId: string): Observable<Employee | null> {
		return this.firestore.toObjectStream(
			this.firestore.getMerchantEmployees(merchantId).doc(userId)
		);
	}

	getLocations(merchantId: string): Observable<MerchantLocation[]> {
		return this.firestore.toListStream(
			this.firestore.getMerchantLocations(merchantId)
		);
	}

	getLocation(
		merchantId: string,
		locationId: string
	): Observable<MerchantLocation | null> {
		return this.firestore.toObjectStream(
			this.firestore.getMerchantLocation(merchantId, locationId)
		);
	}

	getMerchants(): Observable<Merchant[]> {
		return this.firestore.toListStream(this.firestore.getMerchants());
	}

	getUserInvitations(merchantId: string): Observable<UserInvitation[]> {
		return this.fb.toListStream(this.fb.getMerchantUserInvitations(merchantId));
	}

	merchantSlug(name): string {
		return slug(name, {lower: true}); //tslint:disable-line:no-unsafe-any
	}

	uploadLogo(merchant: Merchant, blob: Blob) {
		const fileExtension = mimeTypes.getExtension(blob.type); //tslint:disable-line:no-unsafe-any
		if (merchant.logoFilename) {
			const oldLogoRef = this.firestore.getStorageReference(
				`logo/${merchant.id}/${merchant.logoFilename}`
			);
			oldLogoRef.delete();
		}
		const logoSlug = this.fb.generateId("logo");
		const logoFilename = `${logoSlug}.${fileExtension}`;
		this.firestore.getMerchant(merchant.$key!).update({logoFilename});
		return this.firestore
			.getStorageReference(`logo/${merchant.id}/${logoFilename}`)
			.put(blob);
	}

	getProviders(): Observable<Provider[]> {
		return this.firestore.toListStream(this.firestore.getProviders());
	}

	setMerchantUserEmployment(
		merchant: Merchant,
		user: UserSummary,
		employmentRelationship: EmploymentRelationship
	): Promise<any> {
		const merchantSummary: MerchantSummary = SummaryFromMerchant(merchant);

		return this.firestore.runTransaction(transaction => {
			return Promise.all([
				transaction.set(
					this.firestore.getUserEmployer(user.key, merchant.$key!).ref,
					{
						employmentRelationship,
						merchant: merchantSummary,
					}
				),
				transaction.set(
					this.firestore.getMerchantEmployee(merchant.$key!, user.key).ref,
					{
						employmentRelationship,
						user,
					}
				),
			]);
		});
	}

	getNewMerchantManager(): EmploymentRelationship {
		return {
			role: MerchantRole.manager,
			status: MerchantUserStatus.active,
			universal: true,
		};
	}

	getNewMerchantUser(): EmploymentRelationship {
		return {
			role: MerchantRole.agent,
			status: MerchantUserStatus.inactive,
			universal: false,
		};
	}

	getNewUserInvitation(): UserInvitation {
		return {
			displayName: "",
			email: "",
			phoneNumber: "",
			role: MerchantRole.agent,
			status: UserInvitationStatus.invited,
			universal: false,
		};
	}

	getStatusIcon(merchantStatus) {
		switch (merchantStatus) {
			case "approved":
				return "eye";
			case "disabled":
				return "eye-off";
			case "rejected":
				return "thumbs-down";
			case "pending":
				return "hand";
		}
	}

	getPendingMerchants(): Observable<Merchant[]> {
		return this.firestore.toListStream(
			this.firestore.getMerchants(ref => ref.where("status", "==", "pending"))
		);
	}

	getRecentMerchantValidations(
		merchantId: string,
		count: number
	): Observable<RewardDetails[]> {
		return this.rewardsService
			.searchRewards({
				count,
				merchantId,
			})
			.pipe(
				childJoin<Reward, any, RewardDetails>(
					(reward: Reward) =>
						combineLatest(
							this.firestore.toObjectStream(
								this.firestore.getMerchantLocation(
									merchantId,
									reward.validations[reward.validations.length - 1].locationId
								)
							),
							this.firestore.toObjectStream(
								this.firestore.getMerchantOffer(merchantId, reward.offerId)
							)
						),
					(reward: Reward, [location, offer]: [Location, Offer]) => ({
						location,
						offer,
						reward,
						rewardId: reward.$key,
					})
				)
			);
	}

	addLocationsFromGoogleMapData(
		merchant: Merchant,
		selectedPlaces: google.maps.places.PlaceResult[]
	): Promise<(string | null)[]> {
		return Promise.all(
			selectedPlaces.map(place =>
				this.addLocationFromGoogleMapData(merchant, place).catch(() => null)
			)
		);
	}

	async addLocationFromGoogleMapData(
		merchant: Merchant,
		place: google.maps.places.PlaceResult
	): Promise<string> {
		return this.addLocation({
			...this.getNewMerchantLocation(merchant),
			...(await this.mappingService
				.getLocationInfo(place.place_id)
				.toPromise()),
			isAddressMatchPlace: true,
			useGoogleMapsLocationData: true,
		});
	}
}

export const torontoLatitude = 43.656325;
export const torontoLongitude = -79.380904;
export const sanFranciscoLatitude = 37.7576948;
export const sanFranciscoLongitude = -122.472705;
