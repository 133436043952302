import {Component, ViewChild} from "@angular/core";
import {ModalController} from "@ionic/angular";
import {Logger} from "../../../core/logger/logger.service";
import {ScanComponent} from "../scan.component";

@Component({
	selector: "page-mb-scan",
	styleUrls: ["consumer-scan-modal.component.scss"],
	templateUrl: "consumer-scan-modal.component.html",
})
export class ConsumerScanModal {
	@ViewChild(ScanComponent)
	scanComponent: ScanComponent;

	constructor(private modalCtrl: ModalController, private logger: Logger) {}

	ionViewDidEnter() {
		this.scanComponent.scan();
	}

	dismiss() {
		this.modalCtrl.dismiss();
	}
}
