import {Component} from "@angular/core";
import {Store} from "@ngrx/store";
import {Observable} from "rxjs";
import * as fromUser from "../+state";
import {UserActions} from "../+state/user.actions";
import {Language} from "../../../../../../lib/model/language.model";
import {MovebeState} from "../../../movebe-state.model";

@Component({
	selector: "movebe-language-chooser",
	styleUrls: ["./language-chooser.component.scss"],
	templateUrl: "./language-chooser.component.html",
})
export class LanguageChooserComponent {
	language$: Observable<Language>;
	readonly supportedLanguages = Object.keys(Language);

	constructor(private store: Store<MovebeState>) {
		this.language$ = this.store.select(fromUser.getLanguage);
	}

	languageChanged(language: Language) {
		this.store.dispatch(UserActions.SelectLanguage(language));
	}
}
