import {Pipe, PipeTransform} from "@angular/core";
import {Moment} from "moment";
import {DateFormatPipe as NgxMomentDateFormatPipe} from "ngx-moment";
import {isTimestamp, Timestamp} from "../../../../../lib/model/timestamp.model";

/**
 * Custom wrapper for ngx-moment DateFormatPipe that also accepts Firestore Timestamp objects
 */
@Pipe({name: "movebeDateFormat"})
export class DateFormatPipe implements PipeTransform {
	transform(value: Date | Moment | Timestamp, ...args: any[]): string {
		return new NgxMomentDateFormatPipe().transform(
			isTimestamp(value) ? value.toDate() : value,
			...args
		);
	}
}
