import {Injectable} from "@angular/core";
import {combineLatest, fromEvent, merge, Observable, of} from "rxjs";
import {
	distinctUntilChanged,
	filter,
	first,
	map,
	mapTo,
	shareReplay,
} from "rxjs/operators";
import {FirebaseService} from "../firebase/firebase.service";
import {ConnectionStatus, DisconnectAlert} from "./connection-status.model";

@Injectable()
export class ConnectionStatusService {
	readonly connectionStatus$: Observable<any>;
	readonly disconnectAlert$: Observable<DisconnectAlert>;

	private readonly firebaseIsConnected$: Observable<boolean | null>;
	private readonly isOnLine$: Observable<boolean>;

	constructor(private fb: FirebaseService) {
		this.firebaseIsConnected$ = this.fb.getFirebaseIsConnected().valueChanges();

		this.isOnLine$ = merge(
			of(navigator.onLine),
			fromEvent(window, "online").pipe(mapTo(true)),
			fromEvent(window, "offline").pipe(mapTo(false))
		);

		this.connectionStatus$ = combineLatest(
			this.isOnLine$,
			this.firebaseIsConnected$
		).pipe(
			map(([isNetworkConnected, isFirebaseConnected]) => {
				if (!isNetworkConnected) {
					return ConnectionStatus.noNetworkConnection;
				} else if (!isFirebaseConnected) {
					return ConnectionStatus.noFirebaseConnection;
				} else {
					return ConnectionStatus.connected;
				}
			}),
			distinctUntilChanged(),
			shareReplay(1)
		);

		this.disconnectAlert$ = this.connectionStatus$.pipe(
			filter(
				connectionStatus => connectionStatus !== ConnectionStatus.connected
			),
			map(connectionStatus => ({
				connectionStatus,
				connectionStatusEndedPromise: this.connectionStatus$
					.pipe(first())
					.toPromise(),
			}))
		);
	}
}
