import {NgModule} from "@angular/core";
import {RouterModule, Routes} from "@angular/router";
import {config} from "../../../config";

const routes: Routes = [
	{
		path: "c",
		loadChildren: "./app-modes/consumer/consumer.module#ConsumerModule",
	},
	{path: "a", loadChildren: "./app-modes/admin/admin.module#AdminModule"},
	{
		path: "m",
		loadChildren: "./app-modes/merchant/merchant.module#MerchantModule",
	},
	{path: "i", loadChildren: "./init/init.module#InitPageModule"},
	{path: "", redirectTo: "i", pathMatch: "full"},
];

@NgModule({
	imports: [RouterModule.forRoot(routes, {enableTracing: config.enableRouterTracing})],
	exports: [RouterModule],
})
export class AppRoutingModule {}
