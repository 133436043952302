import {Directive, HostBinding, Input, OnChanges, OnDestroy} from "@angular/core";
import {Subject} from "rxjs";
import {filter, takeUntil} from "rxjs/operators";
import {Merchant} from "../../../../../lib/model/merchant.model";
import {MerchantsService} from "../../core/merchants/merchants.service";
import {filterNulls} from "../../lib/rxjs-operators/filter-nulls";

@Directive({
	selector: "img[movebeMerchantLogo]",
})
export class MerchantLogoDirective implements OnChanges, OnDestroy {
	readonly BLANK_IMAGE = "assets/img/1x1.transparent.png";

	@HostBinding("style.height")
	height = "auto";
	@HostBinding("style.width")
	width = "auto";
	@HostBinding("style.object-fit")
	objectFit = "contain";
	@HostBinding("src")
	src = this.BLANK_IMAGE;

	@Input()
	imageSize: number;
	@Input()
	merchant: Merchant;
	@Input()
	merchantId: string;
	private done$ = new Subject<void>();

	constructor(private merchants: MerchantsService) {}

	ngOnChanges() {
		this.height = `${this.imageSize}.px`;
		this.width = `${this.imageSize}.px`;
		this.src =
			this.merchant && this.merchant.logoUrl
				? this.getSizedLogoUrl(this.merchant)
				: "/assets/icon/merchants.svg";

		if (this.merchantId) {
			this.merchants
				.getMerchant(this.merchantId)
				.pipe(
					takeUntil(this.done$),
					filterNulls(),
					filter(merchant => !!merchant.logoUrl)
				)
				.subscribe(merchant => (this.src = this.getSizedLogoUrl(merchant)));
		}
	}

	getSizedLogoUrl(merchant: Merchant) {
		return `${merchant.logoUrl}=s${this.imageSize}`;
	}

	ngOnDestroy() {
		this.done$.next();
		this.done$.complete();
	}
}
