import {Observable} from "rxjs";
import {filter, map, pairwise, startWith} from "rxjs/operators";

/**
 * Operator that filters out null or undefined values in the stream if the previous value wasn't null.
 * Useful for subscribing to firebase/firestore data where the doc could get removed on the server;
 * subscriber hangs on to the last value retrieved before deletion instead of null being emitted.
 * TODO: come up with a better name for this operator
 */
export const ignoreNewNulls = () => <T>(
	source: Observable<T | null | undefined>
) =>
	source.pipe(
		startWith(null),
		pairwise(),
		filter(
			([prev, current]: [T, T]) => !(hasValue(prev) && !hasValue(current))
		),
		map(([prev, current]: [T, T]) => current)
	);

function hasValue(obj) {
	return obj !== null && obj !== undefined;
}
