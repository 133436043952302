import {Component, Input} from "@angular/core";
import {select, Store} from "@ngrx/store";
import {Observable} from "rxjs";
import * as fromApp from "../../+state";
import {AppMode} from "../../../../../lib/model/app-mode.model";
import {LatLngLiteral} from "../../../../../lib/model/mapping/lat-lng-literal.model";
import {Merchant} from "../../../../../lib/model/merchant.model";
import * as fromMerchant from "../../app-modes/merchant/+state";
import {GeolocationService} from "../../core/geolocation/geolocation.service";
import {Logger} from "../../core/logger/logger.service";
import {PromiseModalController} from "../../core/modal/modal.service";
import * as fromUser from "../../lib/user/+state";
import {AccountModal} from "../../lib/user/account/account.modal";
import {MovebeState} from "../../movebe-state.model";
import {MerchantChooserModal} from "../merchant-chooser/merchant-chooser.modal";
import {ConsumerScanModal} from "../scanning/consumer/consumer-scan-modal.component";
import {MerchantScanModal} from "../scanning/merchant/merchant-scan-modal.component";

@Component({
	selector: "movebe-page-header-toolbar",
	styleUrls: ["./page-header-toolbar.component.scss"],
	templateUrl: "./page-header-toolbar.component.html",
})
export class PageHeaderToolbarComponent {
	@Input()
	title: string;
	readonly currentMerchant$: Observable<Merchant | undefined>;
	readonly appMode$: Observable<AppMode | undefined>;
	readonly AppMode = AppMode;
	readonly isUserSignedIn$: Observable<boolean>;
	readonly currentLatLng$: Observable<LatLngLiteral>;

	constructor(
		private logger: Logger,
		private store: Store<MovebeState>,
		private modalCtrl: PromiseModalController,
		private geolocationService: GeolocationService
	) {
		this.currentMerchant$ = this.store.pipe(
			select(fromMerchant.getCurrentMerchant),
		);
		this.appMode$ = this.store.pipe(select(fromApp.getAppMode));
		this.isUserSignedIn$ = this.store.pipe(select(fromUser.getIsUserSignedIn));
		this.currentLatLng$ = this.geolocationService.currentLatLng$;
	}

	openMerchantsPage() {
		this.modalCtrl.presentModal(MerchantChooserModal);
	}

	openScanPage(appMode: AppMode) {
		this.modalCtrl.presentModal(
			appMode === AppMode.merchant ? MerchantScanModal : ConsumerScanModal
		);
	}

	openAccountPage() {
		this.modalCtrl.presentModal(AccountModal);
	}
}
