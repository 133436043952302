/*tslint:disable:member-ordering*/
import {Injectable} from "@angular/core";
import {AngularFireAuth} from "@angular/fire/auth";
import {AlertController} from "@ionic/angular";
import {Actions} from "@ngrx/effects";
import {Store} from "@ngrx/store";
import {TranslateService} from "@ngx-translate/core";
import {auth, User} from "firebase/app";
import {Observable} from "rxjs";
import {filter, switchMap} from "rxjs/operators";
import {AppLink, AppLinkType} from "../../../../../lib/model/app-link.model";
import {CredentialsWithName} from "../../../../../lib/model/user/credentials.model";
import {UserProfile} from "../../../../../lib/model/user/user-profile.model";
import {appEnvironment} from "../../app-environment";
import {filterNulls} from "../../lib/rxjs-operators/filter-nulls";
import * as fromUser from "../../lib/user/+state/index";
import {UserActions} from "../../lib/user/+state/user.actions";
import {MovebeState} from "../../movebe-state.model";
import {TelephoneNumberPipe} from "../../shared/telephone-number/telephone-number.pipe";
import {AppLinkService} from "../app-links/app-link.service";
import {BusyService} from "../busy/busy.service";
import {FirebaseService} from "../firebase/firebase.service";
import {FirestoreService} from "../firebase/firestore.service";
import {MovebeApiService} from "../movebe-api/movebe-api.service";
import {UserInvitation} from "./user-invitation.model";

@Injectable()
export class CurrentUserService {
	readonly authAppLinkEvent$: Observable<AppLink>;
	readonly authState$: Observable<User | null>;

	constructor(
		private action$: Actions,
		private afAuth: AngularFireAuth,
		private alertCtrl: AlertController,
		private api: MovebeApiService,
		private busyService: BusyService,
		private fb: FirebaseService,
		private firestore: FirestoreService,
		private translate: TranslateService,
		private telephoneNumberPipe: TelephoneNumberPipe,
		private appLinkService: AppLinkService,
		private store: Store<MovebeState>
	) {
		this.authState$ = this.store.select(fromUser.getUserAuthState);

		this.authAppLinkEvent$ = appLinkService.appLinkEvent$.pipe(
			filter(appLink => appLink.type === AppLinkType.auth)
		);
	}

	init() {
		this.store.dispatch(UserActions.QueryAuthState());
		this.authAppLinkEvent$.subscribe(appLink => {
			const validated = this.store.dispatch(
				UserActions.SignInWithAppLinkAuthId(appLink.value)
			);
		});
		return Promise.resolve();
	}

	getAuthToken(): Observable<string> {
		return this.authState$.pipe(
			filterNulls(),
			switchMap(authState => authState.getIdToken(false))
		);
	}

	pushUserInvitation(merchantId: string, userInvitation: UserInvitation) {
		this.fb.pushUserInvitation(merchantId, userInvitation);
	}

	addUpdateUserInvitation(
		userId: string,
		merchantId: string,
		userInvitation: UserInvitation
	) {
		this.fb.addUpdateUserInvitation(merchantId, userId, userInvitation);
	}

	linkPasswordProvider(credentials: CredentialsWithName): Promise<void> {
		const credential: auth.AuthCredential = auth.EmailAuthProvider.credential(
			credentials.email,
			credentials.password
		);
		const currentUser = this.afAuth.auth.currentUser!;
		const passwordLinkedPromise = this.refreshSignIn()
			.then(() =>
				currentUser.updateProfile({
					displayName: credentials.displayName,
					photoURL: null,
				})
			)
			.then(() => currentUser.linkWithCredential(credential))
			.then((user: User) => {
				this.store.dispatch(UserActions.AuthStateReceived(user));
				return user.sendEmailVerification();
			});
		this.busyService.setBusy(
			passwordLinkedPromise,
			this.translate.instant("AUTH.ADDING_EMAIL_PASSWORD")
		);
		return passwordLinkedPromise;
	}

	refreshSignIn(): Promise<User> {
		return this.api
			.getAuthToken()
			.toPromise()
			.then(authToken => this.afAuth.auth.signInWithCustomToken(authToken))
			.then(userCredential => userCredential.user);
	}

	sendEmailVerification(): Promise<void> {
		return Promise.resolve(
			this.afAuth.auth.currentUser!.sendEmailVerification()
		);
	}

	unlinkPasswordProvider(): Promise<void> {
		const currentUser = this.afAuth.auth.currentUser!;
		return this.refreshSignIn()
			.then(
				() =>
					!appEnvironment.isProduction
						? currentUser.updateEmail(`${currentUser.uid}@user.movebe.com`)
						: null
			)
			.then(() => currentUser.unlink("password"))
			.then((user: User) =>
				this.store.dispatch(UserActions.AuthStateReceived(user))
			);
	}

	getUserProfile(userId: string): Observable<UserProfile | null> {
		return this.firestore.toObjectStream(this.firestore.getUserProfile(userId));
	}
}
