import {Injectable} from "@angular/core";
import {Actions, Effect} from "@ngrx/effects";
import {select, Store} from "@ngrx/store";
import {TranslateService} from "@ngx-translate/core";
import {mimeTypes} from "mime-wrapper";
import {Observable} from "rxjs";
import {concatMap, first, map, switchMap, withLatestFrom} from "rxjs/operators";
import {ofType, toPayload} from "ts-action-operators";
import {AppActions} from "../../../+state/app.actions";
import {AppMode} from "../../../../../../lib/model/app-mode.model";
import {
	Employer,
	EmploymentRelationship,
	MerchantRole,
	MerchantUserStatus,
} from "../../../../../../lib/model/employment-relationship.model";
import {
	Merchant,
	MerchantSummary,
	SummaryFromMerchant,
} from "../../../../../../lib/model/merchant.model";
import {Settings} from "../../../../../../lib/model/settings.model";
import {TermsAgreement} from "../../../../../../lib/model/terms-agreement.model";
import {
	UserProfile,
	UserSummary,
} from "../../../../../../lib/model/user/user-profile.model";
import {BusyService} from "../../../core/busy/busy.service";
import {FirestoreService} from "../../../core/firebase/firestore.service";
import {Logger} from "../../../core/logger/logger.service";
import {MerchantsService} from "../../../core/merchants/merchants.service";
import {PromiseModalController} from "../../../core/modal/modal.service";
import {MovebeApiService} from "../../../core/movebe-api/movebe-api.service";
import {SettingsService} from "../../../core/settings/settings.service";
import {MovebeState} from "../../../movebe-state.model";
import {TermsModal} from "../../../shared/terms-and-conditions/terms.modal";
import {filterNulls} from "../../rxjs-operators/filter-nulls";
import * as fromUser from "../../user/+state";
import {UserActions} from "../../user/+state/user.actions";
import {AddEmailModal} from "../../user/add-email/add-email.modal";
import {VerifyEmailModal} from "../../user/verify-email/verify-email.modal";
import {
	BecomeMerchantAction,
	BecomeMerchantModalResult,
} from "../become-a-merchant/become-a-merchant-modal-result.model";
import {BecomeAMerchantModal} from "../become-a-merchant/become-a-merchant.modal";
import {EnrollMerchantModal} from "../enroll/enroll-merchant.modal";
import {JoinExistingMerchantModal} from "../join-existing-merchant/join-existing-merchant.modal";
import {MerchantMembershipActions} from "./merchant-membership.actions";

@Injectable()
export class MerchantMembershipEffects {
	readonly currentUserId$ = this.store.pipe(
		select(fromUser.getUserId),
		filterNulls()
	);
	readonly userProfile$ = this.store.pipe(
		select(fromUser.getUserProfile),
		filterNulls()
	);
	readonly userIsLinkedWithEmail$ = this.store.pipe(
		select(fromUser.getIsUserAccountLinkedWithEmail),
		filterNulls()
	);
	readonly userIsEmailVerified$ = this.store.pipe(
		select(fromUser.getIsUserEmailVerified),
		filterNulls()
	);
	readonly settings$: Observable<Settings | null> = this.settingsService.getSettings();

	@Effect()
	readonly StartChatEffect$ = this.actions$.pipe(
		ofType(MerchantMembershipActions.BecomeMerchant),
		withLatestFrom(
			this.userProfile$,
			this.userIsLinkedWithEmail$,
			this.userIsEmailVerified$,
			this.settings$
		),
		map(([, userProfile, isLinkedWithEmail, isEmailVerified, settings]) => {
			if (!isLinkedWithEmail) {
				return MerchantMembershipActions.PresentEmailPasswordModal();
			}
			if (!isEmailVerified) {
				return MerchantMembershipActions.PresentVerifyEmailModal();
			}
			if (!userProfile.termsAgreements) {
				userProfile.termsAgreements = [];
			}
			if (
				!userProfile.termsAgreements.some(
					(agreed: TermsAgreement) =>
						agreed.type === "merchant" &&
						Math.trunc(agreed.version) ===
							Math.trunc(settings!.termsAndConditionsVersions.merchant)
				)
			) {
				return MerchantMembershipActions.PresentAcceptTermsModal();
			}
			return MerchantMembershipActions.PresentJoinOrAddModal();
		})
	);

	@Effect()
	readonly RequireEmailPasswordEffect$ = this.actions$.pipe(
		ofType(MerchantMembershipActions.PresentEmailPasswordModal),
		concatMap(() =>
			this.modalCtrl.presentModal(AddEmailModal, {joiningMerchant: true})
		),
		map(
			modalResult =>
				modalResult
					? MerchantMembershipActions.BecomeMerchant()
					: MerchantMembershipActions.CancelJoin()
		)
	);

	@Effect()
	readonly RequireEmailVerifiedEffect$ = this.actions$.pipe(
		ofType(MerchantMembershipActions.PresentVerifyEmailModal),
		concatMap(() => this.modalCtrl.presentModal(VerifyEmailModal)),
		map(
			modalResult =>
				modalResult
					? MerchantMembershipActions.BecomeMerchant()
					: MerchantMembershipActions.CancelJoin()
		)
	);

	@Effect()
	readonly RequireAcceptedTermsEffect$ = this.actions$.pipe(
		ofType(MerchantMembershipActions.PresentAcceptTermsModal),
		concatMap(() =>
			this.modalCtrl.presentModal(
				TermsModal,
				{termsTemplate: "merchant"},
				false
			)
		),
		map(
			modalResult =>
				modalResult
					? MerchantMembershipActions.AcceptTerms()
					: MerchantMembershipActions.CancelJoin()
		)
	);

	@Effect()
	readonly PromptJoinOrAddMerchantEffect$ = this.actions$.pipe(
		ofType(MerchantMembershipActions.PresentJoinOrAddModal),
		concatMap(() =>
			this.modalCtrl.presentModal<BecomeMerchantModalResult>(
				BecomeAMerchantModal
			)
		),
		map(modalResult => {
			if (!(modalResult && modalResult.action)) {
				return MerchantMembershipActions.CancelJoin();
			}
			switch (modalResult.action) {
				case BecomeMerchantAction.EnrollNew:
					return MerchantMembershipActions.PresentEnrollNewMerchantModal();
				case BecomeMerchantAction.JoinExisting:
					return MerchantMembershipActions.JoinMerchant();
			}
		})
	);

	@Effect()
	readonly AcceptTermsEffect$ = this.actions$.pipe(
		ofType(MerchantMembershipActions.AcceptTerms),
		withLatestFrom(this.currentUserId$, this.settings$, this.userProfile$),
		concatMap(([appMode, userId, settings, userProfile]) => {
			const termsAgreements = userProfile.termsAgreements || [];
			termsAgreements.push({
				date: new Date(),
				type: "merchant",
				version: settings!.termsAndConditionsVersions.merchant,
			});
			return this.updateUserProfile(userId, {termsAgreements});
		}),
		map(result => MerchantMembershipActions.TermsAccepted())
	);

	@Effect()
	readonly TermsAcceptedEffect$ = this.actions$.pipe(
		ofType(MerchantMembershipActions.TermsAccepted),
		map(() => MerchantMembershipActions.BecomeMerchant())
	);

	@Effect()
	readonly JoinMerchantEffect$$ = this.actions$.pipe(
		ofType(MerchantMembershipActions.JoinMerchant),
		concatMap(() => this.modalCtrl.presentModal(JoinExistingMerchantModal)),
		map(() => MerchantMembershipActions.JoinExistingMerchantModalPresented())
	);

	@Effect()
	readonly CreateMerchantEffect$ = this.actions$.pipe(
		ofType(MerchantMembershipActions.PresentEnrollNewMerchantModal),
		concatMap(() => this.modalCtrl.presentModal(EnrollMerchantModal)),
		map(() => MerchantMembershipActions.EnrollMerchantModalPresented())
	);

	@Effect()
	readonly EnrollMerchantModalPresentedEffect$ = this.actions$.pipe(
		ofType(MerchantMembershipActions.MerchantAdded),
		toPayload(),
		switchMap(({merchant, employer}) => [
			AppActions.SelectAppMode(AppMode.merchant),
			UserActions.SelectCurrentMerchant(employer),
		])
	);

	@Effect()
	readonly AddMerchantEffect$ = this.actions$.pipe(
		ofType(MerchantMembershipActions.AddMerchant),
		toPayload(),
		withLatestFrom(this.userProfile$),
		concatMap(([{merchant, logo}, user]) =>
			this.enrollMerchant(merchant, logo, user)
		),
		switchMap(({merchant, employer}) => [
			MerchantMembershipActions.MerchantAdded({merchant, employer}),
		])
	);

	constructor(
		private actions$: Actions,
		private busyService: BusyService,
		private merchantsService: MerchantsService,
		private movebeApiService: MovebeApiService,
		private modalCtrl: PromiseModalController,
		private logger: Logger,
		private settingsService: SettingsService,
		private store: Store<MovebeState>,
		private translate: TranslateService,
		private firestore: FirestoreService
	) {}

	updateUserProfile(
		userId: string,
		userProfile: Partial<UserProfile>
	): Promise<void> {
		return this.firestore.getUserProfile(userId).update(userProfile);
	}

	getMerchant(merchantId: string): Observable<Merchant | null> {
		return this.firestore.toObjectStream(
			this.firestore.getMerchant(merchantId)
		);
	}

	enrollMerchant(
		merchant: Merchant,
		logo: Blob,
		user: UserProfile
	): Promise<{merchant: Merchant; employer: Employer}> {
		const enrollMerchantPromise = this.addMerchant(merchant).then(
			createdMerchant =>
				Promise.all([
					logo ? this.uploadLogo(createdMerchant, logo) : Promise.resolve(),
					this.addMerchantUser(createdMerchant, user, MerchantRole.owner),
				]).then(([, employer]) => {
					return {
						employer,
						merchant: createdMerchant,
					};
				})
		);
		this.busyService.setBusy(
			enrollMerchantPromise,
			this.translate.instant("MERCHANT_MEMBERSHIP.ENROLLING_MERCHANT")
		);
		return enrollMerchantPromise;
	}

	addMerchant(newMerchant: Merchant): Promise<Merchant> {
		return this.movebeApiService
			.putMerchant(newMerchant)
			.pipe(
				switchMap((merchantId: string) => this.getMerchant(merchantId)),
				filterNulls(),
				first()
			)
			.toPromise();
	}

	uploadLogo(merchant: Merchant, blob: Blob): Promise<void> {
		const fileExtension = mimeTypes.getExtension(blob.type); //tslint:disable-line:no-unsafe-any
		const logoSlug = this.firestore.generateId("logo");
		const logoFilename = `${logoSlug}.${fileExtension}`;
		const storageRef = this.firestore.getStorageReference(
			`logo/${merchant.$key}/${logoFilename}`
		);
		return storageRef
			.put(blob)
			.then(uploadTask => {
				if (merchant.logoFilename) {
					return this.firestore
						.getStorageReference(
							`logo/${merchant.$key}/${merchant.logoFilename}`
						)
						.delete();
				}
			})
			.then(() =>
				this.movebeApiService
					.getImageServingUrl(storageRef.fullPath)
					.toPromise()
			)
			.then(logoUrl => {
				return this.firestore
					.getMerchant(merchant.$key!)
					.update({logoFilename, logoUrl});
			});
	}

	addMerchantUser(
		merchant: Merchant,
		user: UserProfile,
		role: MerchantRole
	): Promise<Employer> {
		const employmentRelationship: EmploymentRelationship = {
			role,
			status: MerchantUserStatus.active,
			universal: false,
		};
		const merchantSummary: MerchantSummary = SummaryFromMerchant(merchant);
		const userSummary: UserSummary = {
			displayName: user.displayName,
			key: user.$key!,
		};
		return this.firestore
			.runTransaction(transaction => {
				return Promise.all([
					transaction.set(
						this.firestore.getUserEmployer(user.$key!, merchant.$key!).ref,
						{
							employmentRelationship,
							merchant: merchantSummary,
						}
					),
					transaction.set(
						this.firestore.getMerchantEmployee(merchant.$key!, user.$key!).ref,
						{
							employmentRelationship,
							user: userSummary,
						}
					),
				]);
			})
			.then(() =>
				this.firestore
					.toObjectStream(
						this.firestore.getUserEmployer(user.$key!, merchant.$key!)
					)
					.pipe(
						filterNulls(),
						first()
					)
					.toPromise()
			);
	}
}
