/*tslint:disable:member-ordering*/
import {Injectable} from "@angular/core";
import {Platform} from "@ionic/angular";
import {NgMapApiLoader} from "@ngui/map";
import {bindCallback, Observable, of, throwError} from "rxjs";
import RateLimiter from "rxjs-ratelimiter";
import {flatMap, map, retry, shareReplay} from "rxjs/operators";
import {LocationAddress} from "../../../../../lib/model/mapping/address.model";
import {LatLngLiteral} from "../../../../../lib/model/mapping/lat-lng-literal.model";
import {
	LocationInfo,
	OpeningHours,
	OpeningPeriod,
} from "../../../../../lib/model/mapping/location.model";
import {MerchantLocation} from "../../../../../lib/model/merchant-location.model";
import GeocoderAddressComponent = google.maps.GeocoderAddressComponent;
import LatLng = google.maps.LatLng;
import PlaceDetailsRequest = google.maps.places.PlaceDetailsRequest;
import PlaceResult = google.maps.places.PlaceResult;
import PlaceSearchRequest = google.maps.places.PlaceSearchRequest;
import PlacesServiceStatus = google.maps.places.PlacesServiceStatus;
import TextSearchRequest = google.maps.places.TextSearchRequest;

@Injectable()
export class MappingService {
	private readonly rateLimiterRequestsInterval = 1;
	private readonly rateLimiterIntervalLength = 500;
	private readonly googleMapsRetryCount = 6;
	private googleMapsApiRateLimiter = new RateLimiter(
		this.rateLimiterRequestsInterval,
		this.rateLimiterIntervalLength
	);
	private geocoder: google.maps.Geocoder;
	private places: google.maps.places.PlacesService;

	constructor(
		private platform: Platform,
		private ngMapApiLoader: NgMapApiLoader
	) {}

	init() {
		return new Promise<void>(resolve => {
			this.ngMapApiLoader.load();
			this.ngMapApiLoader.api$.subscribe(() => {
				resolve();
			});
		}).then(() => {
			const el = document.createElement("div");
			this.geocoder = new google.maps.Geocoder();
			this.places = new google.maps.places.PlacesService(el);
		});
	}

	computeDistanceBetween(from: google.maps.LatLng, to: google.maps.LatLng) {
		return google.maps.geometry.spherical.computeDistanceBetween(from, to);
	}

	getPlaces(
		query: string,
		location: google.maps.LatLng,
		searchRadius: number
	): Observable<PlaceResult[]> {
		return this.textSearch({query, location, radius: searchRadius});
	}

	getMerchantLocations(
		merchantName: string,
		location: LatLngLiteral
	): Observable<PlaceResult[]> {
		return this.nearbySearch({
			keyword: merchantName,
			location,
			rankBy: google.maps.places.RankBy.DISTANCE,
		});
	}

	getLocation(query: string, location: LatLng): Observable<PlaceResult[]> {
		return this.textSearch({location, query});
	}

	getLocationInfo(placeId: string): Observable<LocationInfo> {
		return this.getDetails({placeId}).pipe(
			map(place => this.googlePlaceToLocation(place))
		);
	}

	addLocationDetails(
		location: MerchantLocation,
		place: PlaceResult
	): Observable<LocationInfo> {
		return this.getDetails({placeId: place.place_id}).pipe(
			map((details: PlaceResult) => {
				if (details.hasOwnProperty("vicinity")) {
					return this.addGooglePlaceInfoToLocation(location, details);
				} else {
					throw Error("Places query did not return a vicinity");
				}
			})
		);
	}

	private addGooglePlaceInfoToLocation(
		location: LocationInfo,
		place: PlaceResult
	): LocationInfo {
		return {
			...location,
			...this.googlePlaceToLocation(place),
		};
	}

	googlePlaceToLocation(place: PlaceResult): LocationInfo {
		const openingHours = this.parseGoogleOpeningHours(place.opening_hours);
		return {
			address: this.googleAddressComponentsToAddress(place.address_components),
			geo: this.googleGeometryToLatLng(place.geometry),
			googlePlaceId: place.place_id,
			telephone: place.international_phone_number || "",
			vicinity: place.vicinity,
			...(openingHours ? {openingHours} : null),
		};
	}

	private parseGoogleOpeningHours(
		googleHours: google.maps.places.OpeningHours
	): OpeningHours | undefined {
		const openingHrs =
			googleHours && googleHours.periods
				? googleHours.periods.length === 1
					? {alwaysOpen: true}
					: {days: this.parseGoogleOpeningPeriods(googleHours.periods)}
				: undefined;
		return openingHrs;
	}

	private parseGoogleOpeningPeriods(
		periods: google.maps.places.OpeningPeriod[]
	): OpeningPeriod[] {
		const daysInWeek = 7;
		const minutesInDay = 1440;
		return periods.reduce(
			(result: OpeningPeriod[], item: google.maps.places.OpeningPeriod) => {
				result[item.open.day] = {
					close: item.close
						? this.hhmmToMinutes(item.close.time) +
						  (item.close.day - item.open.day) * minutesInDay
						: minutesInDay,
					closed: false,
					open: this.hhmmToMinutes(item.open.time),
				};
				return result;
			},
			new Array<OpeningPeriod>(daysInWeek).fill({closed: true})
		);
	}

	/**
	 * Converts a time of day in the format used by google maps to a number of minutes
	 * returns 0 of if the input string is improperly formatted
	 * @param {string} hhmm: a string in a "hhmm" format
	 * @returns {number} the number of minutes.
	 */
	hhmmToMinutes(hhmm: string): number {
		const minutesPerHour = 60;
		const stringSplitLocation = 2;
		return (
			Number(hhmm.slice(0, stringSplitLocation)) * minutesPerHour +
				Number(hhmm.slice(stringSplitLocation)) || 0
		);
	}

	googleGeometryToLatLng(
		geometry: google.maps.places.PlaceGeometry
	): LatLngLiteral {
		return {
			lat: geometry.location.lat(),
			lng: geometry.location.lng(),
		};
	}

	googleAddressComponentsToAddress(
		addressComponents: GeocoderAddressComponent[]
	): LocationAddress {
		//function to parse weird Google response address format.
		//see: https://developers.google.com/maps/documentation/geocoding/intro#GeocodingResponses
		const find = (fieldName: string) => {
			const found = addressComponents.find(
				(addressComponent: GeocoderAddressComponent) =>
					addressComponent.types.includes(fieldName)
			);
			return found ? found.short_name : "";
		};

		return {
			country: find("country"),
			municipality: find("locality"),
			postalCode: find("postal_code"),
			state: find("administrative_area_level_1"),
			street: `${find("street_number")} ${find("route")}`,
		};
	}

	toBase26(n: number): string {
		const base = 26;
		let alpha = "";
		while (n > 0) {
			alpha += String.fromCharCode("A".charCodeAt(0) + (--n % base));
			n = Math.floor(n / base);
		}
		return alpha
			.split("")
			.reverse()
			.join("");
	}

	getLabelledMarkerUrl(text, backGroundColor, textColor) {
		return encodeURI(
			`https://chart.googleapis.com/chart?chst=d_bubble_text_small_withshadow&chld=bbT|${text}|${backGroundColor}|${textColor}`
		);
	}

	getParkingMarkerUrl(): string {
		return this.getCordovaLocalFileUrl(
			"assets/img/map-markers/parking-marker-highlight.png"
		);
	}

	getMerchantMarkerUrl(): string {
		return this.getCordovaLocalFileUrl(
			"assets/img/map-markers/merchant-marker-highlight.png"
		);
	}

	private getDetails(
		placeDetailsRequest: PlaceDetailsRequest
	): Observable<PlaceResult> {
		return this.googleMapsApiRateLimiter
			.limit(bindCallback(this.places.getDetails)
				.call(this.places, placeDetailsRequest)
				.pipe(
					flatMap(([place, status]: [PlaceResult, PlacesServiceStatus]) => {
						if (status === google.maps.places.PlacesServiceStatus.OK) {
							return of(place);
						} else {
							return throwError({
								googleMapsApiResponse: {place, status},
								message: "Error getting place details",
								placeDetailsRequest,
							});
						}
					})
				) as Observable<PlaceResult>)
			.pipe(
				retry(this.googleMapsRetryCount),
				shareReplay(1)
			);
	}

	private textSearch(
		textSearchRequest: TextSearchRequest
	): Observable<PlaceResult[]> {
		return this.googleMapsApiRateLimiter
			.limit(bindCallback(this.places.textSearch)
				.call(this.places, textSearchRequest)
				.pipe(
					flatMap(([places, status]: [PlaceResult, PlacesServiceStatus]) => {
						if (status === google.maps.places.PlacesServiceStatus.OK) {
							return of(places);
						} else if (
							status === google.maps.places.PlacesServiceStatus.ZERO_RESULTS
						) {
							return of<PlaceResult[]>([]);
						} else {
							return throwError({
								googleMapsApiResponse: {places, status},
								message: "Error executing text search",
								textSearchRequest,
							});
						}
					})
				) as Observable<PlaceResult[]>)
			.pipe(
				retry(this.googleMapsRetryCount),
				shareReplay(1)
			);
	}

	private nearbySearch(
		placeSearchRequest: PlaceSearchRequest
	): Observable<PlaceResult[]> {
		return this.googleMapsApiRateLimiter
			.limit(bindCallback(this.places.nearbySearch)
				.call(this.places, placeSearchRequest)
				.pipe(
					flatMap(
						([places, status]: [PlaceResult[], PlacesServiceStatus]) =>
							status === google.maps.places.PlacesServiceStatus.OK
								? of(places.filter(place => !place.permanently_closed))
								: status === google.maps.places.PlacesServiceStatus.ZERO_RESULTS
									? of<PlaceResult[]>([])
									: throwError({
											googleMapsApiResponse: {places, status},
											message: "Error executing nearby search",
											placeSearchRequest,
									  })
					)
				) as Observable<PlaceResult[]>)
			.pipe(
				retry(this.googleMapsRetryCount),
				shareReplay(1)
			);
	}

	private getCordovaLocalFileUrl(path: string): string {
		return this.platform.is("cordova") && this.platform.is("android")
			? `file:///android_asset/www/${path}`
			: path;
	}
}
