import {Component, Input, OnInit} from "@angular/core";
import {Observable} from "rxjs";
import {first} from "rxjs/operators";
import {MerchantLocation} from "../../../../../lib/model/merchant-location.model";
import {Merchant} from "../../../../../lib/model/merchant.model";
import {OfferDetails} from "../../../../../lib/model/offer-details.model";
import {MerchantsService} from "../../core/merchants/merchants.service";
import {OffersService} from "../../core/offers/offers.service";
import {OfferScanRequestParams, OfferScanRequestResponse} from "../../core/scan-request/scan-request-params.model";
import {ScanRequest} from "../../core/scan-request/scan-request.model";
import {filterNulls} from "../../lib/rxjs-operators/filter-nulls";
import {ScanRequestReviewComponent} from "./scan-request-review-component.model";

@Component({
	selector: "movebe-offer-scan-request-review",
	templateUrl: "./offer-scan-request-review.component.html",
})
export class OfferScanRequestReviewComponent
	implements OnInit, ScanRequestReviewComponent {
	@Input()
	scanRequest: ScanRequest;
	location$: Observable<MerchantLocation | null>;
	offerDetails$: Observable<OfferDetails | null>;
	merchant$: Observable<Merchant | null>;

	constructor(
		private merchantsService: MerchantsService,
		private offersService: OffersService
	) {}

	ngOnInit(): void {
		const requestParams = this.scanRequest
			.requestParams as OfferScanRequestParams;
		this.merchant$ = this.merchantsService.getMerchant(
			requestParams.merchantId
		);
		this.location$ = this.merchantsService.getLocation(
			requestParams.merchantId,
			requestParams.locationId!
		);
		this.offerDetails$ = this.offersService.getOfferDetails(
			requestParams.merchantId,
			requestParams.locationId!,
			requestParams.offerId
		);
	}

	approve(): Promise<OfferScanRequestResponse> {
		return this.merchant$
			.pipe(
				filterNulls(),
				first()
			)
			.toPromise()
			.then(merchant =>
				this.offersService.validateOffer(this.scanRequest, merchant)
			)
			.then(reward => ({rewardId: reward.$key!}));
	}
}
