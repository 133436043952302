import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from "@angular/common/http";
import {Injectable} from "@angular/core";
import {Store} from "@ngrx/store";
import * as HttpStatus from "http-status-codes";
import {Observable} from "rxjs";
import {map, mergeMap, tap} from "rxjs/operators";
import {AppActions} from "../../+state/app.actions";
import {appEnvironment} from "../../app-environment";
import {MovebeState} from "../../movebe-state.model";
import {FirestoreService} from "../firebase/firestore.service";
import {CurrentUserService} from "../user/current-user.service";

/**
 * Adds Authorization header to http requests going to MoveBe api
 * Also provides notification for unexpected http request errors
 */
@Injectable()
export class MovebeApiInterceptor implements HttpInterceptor {
	readonly apiHostName = new URL(appEnvironment.apiUrl).hostname;

	constructor(
		private firestore: FirestoreService,
		private currentUserService: CurrentUserService,
		private store: Store<MovebeState>
	) {}

	intercept(
		request: HttpRequest<any>,
		next: HttpHandler
	): Observable<HttpEvent<any>> {
		let httpEvent$: Observable<HttpEvent<any>>;
		try {
			if (new URL(request.url).hostname !== this.apiHostName) {
				httpEvent$ = next.handle(request);
			} else {
				httpEvent$ = this.currentUserService.getAuthToken().pipe(
					map(token =>
						request.clone({
							setHeaders: {Authorization: `Bearer ${token}`},
						})
					),
					mergeMap(authReq => next.handle(authReq))
				);
			}
		} catch (err) {
			//ignore exception thrown when parsing relative filesystem urls used to load data files from the filesystem (i.e. "new URL('./file.dat')" throws an exception )
			httpEvent$ = next.handle(request);
		}

		return httpEvent$.pipe(
			tap(
				() => null,
				err => {
					if (
						err instanceof HttpErrorResponse &&
						err.status !== HttpStatus.BAD_REQUEST
					) {
						this.store.dispatch(AppActions.HttpErrorOccurred(err));
					}
				}
			)
		);
	}
}
