import {Pipe, PipeTransform} from "@angular/core";
import {
	CountryCode,
	format,
	NationalNumber,
	NumberFormat,
} from "libphonenumber-js";

@Pipe({name: "movebeTelephoneNumber"})
export class TelephoneNumberPipe implements PipeTransform {
	transform(
		phoneNumber: NationalNumber,
		countryCode: CountryCode,
		outputFormat: NumberFormat
	): string {
		return format(phoneNumber, countryCode, outputFormat);
	}
}
