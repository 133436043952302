import {Injectable} from "@angular/core";
import {Observable} from "rxjs";
import {SurveyResponse} from "../../../../../lib/model/survey/survey-question-response.model";
import {Survey} from "../../../../../lib/model/survey/survey.model";
import {FirestoreService} from "../firebase/firestore.service";
import {MovebeApiService} from "../movebe-api/movebe-api.service";

@Injectable()
export class SurveysService {
	constructor(
		private firestore: FirestoreService,
		private movebeApiService: MovebeApiService
	) {}

	getSurvey(id: string): Observable<Survey | null> {
		return this.firestore.toObjectStream(this.firestore.getSurvey(id));
	}

	saveResponses(response: SurveyResponse): Observable<any> {
		return this.movebeApiService.putSurveyResponses(response);
	}
}
