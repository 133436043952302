import {Component, EventEmitter, Input, OnInit, Output} from "@angular/core";
import {BenefitType} from "../../../../../lib/model/benefit.model";
import {Offer, OfferStatus} from "../../../../../lib/model/offer.model";

@Component({
	selector: "movebe-merchant-offer",
	styleUrls: ["./merchant-offer.component.scss"],
	templateUrl: "./merchant-offer.component.html",
})
export class MerchantOfferComponent implements OnInit {
	BenefitType = BenefitType;
	OfferStatus = OfferStatus;

	@Input()
	showToolbar: boolean;
	@Input()
	offer: Offer;

	@Output()
	activateOfferClicked = new EventEmitter<Offer>();

	@Output()
	deactivateOfferClicked = new EventEmitter<Offer>();

	@Output()
	deleteOfferClicked = new EventEmitter<Offer>();

	@Output()
	archiveOfferClicked = new EventEmitter<Offer>();

	@Input()
	selected: boolean;

	@Input()
	showStamps: boolean;

	stamps: any[];

	ngOnInit() {
		if (this.offer.requiredValidations > 1) {
			this.stamps = new Array(this.offer.requiredValidations).fill({});
		}
	}

	activate(event: MouseEvent) {
		event.stopPropagation();
		this.activateOfferClicked.emit(this.offer);
	}

	deactivate(event: MouseEvent) {
		event.stopPropagation();
		this.deactivateOfferClicked.emit(this.offer);
	}

	delete(event: MouseEvent) {
		event.stopPropagation();
		this.deleteOfferClicked.emit(this.offer);
	}

	archive(event: MouseEvent) {
		event.stopPropagation();
		this.archiveOfferClicked.emit(this.offer);
	}
}
