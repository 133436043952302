import {
	Component,
	EventEmitter,
	Input,
	OnDestroy,
	OnInit,
	Output,
} from "@angular/core";
import {ModalController} from "@ionic/angular";
import {Observable, Subject} from "rxjs";
import {filter, first, last, takeUntil} from "rxjs/operators";
import {AppLink} from "../../../../../lib/model/app-link.model";
import {AppLinkService} from "../../core/app-links/app-link.service";
import {EffectsService} from "../../core/effects/effects.service";
import {Logger} from "../../core/logger/logger.service";
import {
	ScanRequestParams,
	ScanRequestResponse,
} from "../../core/scan-request/scan-request-params.model";
import {
	ApprovedScanRequest,
	ScanRequest,
	ScanRequestAction,
	ScanRequestStatus,
} from "../../core/scan-request/scan-request.model";
import {ScanRequestService} from "../../core/scan-request/scan-request.service";
import {filterNulls} from "../../lib/rxjs-operators/filter-nulls";
import {ScanRequestEnlargedModal} from "./scan-request-enlarged.modal";

@Component({
	selector: "movebe-scan-request",
	styleUrls: ["scan-request.component.scss"],
	templateUrl: "scan-request.component.html",
})
export class ScanRequestComponent implements OnInit, OnDestroy {
	ScanRequestStatus = ScanRequestStatus;

	private readonly done$ = new Subject<void>();
	request$: Observable<ScanRequest | null>;

	@Input()
	action: ScanRequestAction;
	@Input()
	instructionsMessage: string;
	@Input()
	params: ScanRequestParams;
	@Output()
	requestApproved = new EventEmitter<ScanRequestResponse>();

	constructor(
		private appLinkService: AppLinkService,
		private scanRequestService: ScanRequestService,
		private effects: EffectsService,
		private logger: Logger,
		private modalCtrl: ModalController
	) {}

	ngOnInit() {
		if (this.params) {
			this.request$ = this.scanRequestService
				.generateScanRequest(this.action, this.params)
				.pipe(takeUntil(this.done$));

			//delete this scan request from firebase when this component closes
			this.request$.pipe(last()).subscribe(scanRequest => {
				if (scanRequest) {
					this.scanRequestService.removeScanRequest(scanRequest.$key!);
				}
			});

			this.request$
				.pipe(
					filterNulls(),
					filter(
						scanRequest => scanRequest.status === ScanRequestStatus.approved
					)
				)
				.subscribe((scanRequest: ApprovedScanRequest) => {
					this.effects.approve();
					this.requestApproved.emit(scanRequest.responseData);
				});
		}
	}

	enlargeScanRequest() {
		this.modalCtrl
			.create({
				component: ScanRequestEnlargedModal,
				componentProps: {
					instructionsMessage: this.instructionsMessage,
					scanRequest$: this.request$,
				},
			})
			.then(modal => {
				this.request$
					.pipe(
						filterNulls(),
						filter(
							scanRequest => scanRequest.status !== ScanRequestStatus.pending
						),
						first()
					)
					.subscribe(scanRequest => {
						modal.dismiss();
					});
				return modal.present();
			});
	}

	/*tslint:disable:member-ordering*/
	ngOnDestroy() {
		this.done$.next();
		this.done$.complete();
	}

	getAppLinkUrl(appLink: AppLink) {
		return this.appLinkService.generateAppLinkUrl(appLink);
	}

	closeSlidingItem(slidingItem) {
		slidingItem.close();
	}
}
