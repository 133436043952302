import {Component, EventEmitter, Input, OnInit, Output} from "@angular/core";
import {Observable} from "rxjs";
import {BenefitType} from "../../../../../lib/model/benefit.model";
import {MerchantLocation} from "../../../../../lib/model/merchant-location.model";
import {Offer} from "../../../../../lib/model/offer.model";
import {OffersService} from "../../core/offers/offers.service";

@Component({
	selector: "movebe-merchant-offer-list",
	styleUrls: ["merchant-offer-list.component.scss"],
	templateUrl: "merchant-offer-list.component.html",
})
export class MerchantOfferListComponent implements OnInit {
	BenefitType = BenefitType;
	@Input()
	location: MerchantLocation;
	offers$: Observable<Offer[]>;
	@Output()
	offerSelected = new EventEmitter<Offer>();

	constructor(private offersService: OffersService) {}

	ngOnInit(): void {
		this.offers$ = this.offersService.getVisibleOffers(
			this.location.merchantId
		);
	}

	selectOffer(offer: Offer) {
		this.offerSelected.emit(offer);
	}
}
