import {Component, OnInit, ViewChild} from "@angular/core";
import {AlertController, ModalController, NavParams} from "@ionic/angular";
import {select, Store} from "@ngrx/store";
import {TranslateService} from "@ngx-translate/core";
import {Observable} from "rxjs";
import {filter, first, map, startWith, withLatestFrom} from "rxjs/operators";
import * as fromApp from "../../+state";
import {Benefit, Coupon} from "../../../../../lib/model/benefit.model";
import {Employer} from "../../../../../lib/model/employment-relationship.model";
import {MerchantLocation} from "../../../../../lib/model/merchant-location.model";
import {Merchant} from "../../../../../lib/model/merchant.model";
import {Offer} from "../../../../../lib/model/offer.model";
import * as fromMerchant from "../../app-modes/merchant/+state";
import {BusyService} from "../../core/busy/busy.service";
import {Logger} from "../../core/logger/logger.service";
import {MerchantsService} from "../../core/merchants/merchants.service";
import {OffersService} from "../../core/offers/offers.service";
import {MerchantTargetedScanRequestParams} from "../../core/scan-request/scan-request-params.model";
import {ScanRequest, ScanRequestAction, ScanRequestStatus} from "../../core/scan-request/scan-request.model";
import {ScanRequestService} from "../../core/scan-request/scan-request.service";
import {CurrentUserService} from "../../core/user/current-user.service";
import {filterNulls} from "../../lib/rxjs-operators/filter-nulls";
import {ignoreNewNulls} from "../../lib/rxjs-operators/ingore-new-nulls";
import {UserActions} from "../../lib/user/+state/user.actions";
import {MovebeState} from "../../movebe-state.model";
import {OfferScanRequestReviewComponent} from "./offer-scan-request-review.component";
import {RewardScanRequestReviewComponent} from "./reward-scan-request-review.component";
import {ScanRequestReviewComponent} from "./scan-request-review-component.model";

@Component({
	selector: "page-mm-scan-request",
	styleUrls: ["./scan-request-review.modal.scss"],
	templateUrl: "./scan-request-review.modal.html",
})
export class ScanRequestReviewModal implements OnInit {
	ScanRequestStatus = ScanRequestStatus;
	ScanRequestAction = ScanRequestAction;

	benefit: Benefit | Coupon;
	coupon: Coupon;
	location: MerchantLocation;
	merchant: Merchant;
	offer: Offer;
	readonly scanRequest$: Observable<ScanRequest | null>;
	readonly scanRequestId: string;
	private employers$: Observable<Employer[]>;
	private currentMerchant$: Observable<Merchant>;

	isSubmitDisabled = false;
	permissionDenied = false;

	@ViewChild("validateOfferScanRequest")
	validateOfferScanRequest: OfferScanRequestReviewComponent;

	@ViewChild("redeemRewardScanRequest")
	redeemRewardScanRequest: RewardScanRequestReviewComponent;

	@ViewChild("joinMerchantScanRequest")
	joinMerchantScanRequest: RewardScanRequestReviewComponent;

	constructor(
		private alertCtrl: AlertController,
		private busyService: BusyService,
		private navParams: NavParams,
		private currentUserService: CurrentUserService,
		private scanRequestService: ScanRequestService,
		private offersService: OffersService,
		private merchantsService: MerchantsService,
		private store: Store<MovebeState>,
		private translate: TranslateService,
		private logger: Logger,
		private modalController: ModalController
	) {
		this.scanRequestId = this.navParams.get("scanRequestId") as string;
		this.scanRequest$ = this.scanRequestService
			.getScanRequest(this.scanRequestId)
			.pipe(
				ignoreNewNulls(),
				startWith(undefined)
			);
		this.employers$ = this.store.pipe(select(fromApp.getEmployers));
		this.currentMerchant$ = this.store.pipe(
			select(fromMerchant.getCurrentMerchant),
			filterNulls()
		);

		this.scanRequest$
			.pipe(
				filterNulls(),
				filter(
					scanRequest =>
						scanRequest.action === ScanRequestAction.redeemReward ||
						scanRequest.action === this.ScanRequestAction.validateOffer
				),
				map(
					scanRequest =>
						scanRequest.requestParams as MerchantTargetedScanRequestParams
				),
				withLatestFrom(this.currentMerchant$, this.employers$),
				first()
			)
			.subscribe(([requestParams, currentMerchant, employers]) => {
				const requestedMerchantId = requestParams.merchantId;
				if (requestedMerchantId !== currentMerchant.$key!) {
					const neededMerchant = employers.find(
						employer => employer.merchant.key === requestedMerchantId
					);
					if (!neededMerchant) {
						this.permissionDenied = true;
					} else {
						this.store.dispatch(
							UserActions.SelectCurrentMerchant(neededMerchant)
						);
						this.alertCtrl
							.create({
								buttons: [this.translate.instant("LABEL.OK") as string],
								header: this.translate.instant(
									"SCAN_REQUEST.SWITCHING_MERCHANT"
								) as string,
								subHeader: this.translate.instant(
									"SCAN_REQUEST.SWITCHING_MERCHANT_TO",
									{merchantName: neededMerchant.merchant.name}
								) as string,
							})
							.then(alert => alert.present());
					}
				}
			});
	}

	ngOnInit() {
		this.scanRequest$
			.pipe(
				filterNulls(),
				filter(scanRequest => scanRequest.status === ScanRequestStatus.pending),
				first()
			)
			.subscribe(scanRequest => {
				this.scanRequestService.updateScanRequest(
					scanRequest.$key!,
					ScanRequestStatus.scanned
				);
			});
	}

	reject() {
		const rejectPromise = this.scanRequestService.updateScanRequest(
			this.scanRequestId,
			ScanRequestStatus.rejected
		);
		this.busyService.setBusy(rejectPromise);
	}

	approve(scanRequest: ScanRequest) {
		const validatePromise = this.getScanRequestReviewChildComponent(
			scanRequest.action
		)
			.approve()
			.then(responseData => {
				return this.scanRequestService.updateScanRequest(
					this.scanRequestId,
					ScanRequestStatus.approved,
					responseData
				);
			});
		this.busyService.setBusy(
			validatePromise,
			this.translate.instant(
				`SCAN_REQUEST.ACTION.${scanRequest.action.toUpperCase()}.PROCESSING_APPROVAL`
			)
		);
	}

	isValidChanged(isValid: boolean) {
		this.isSubmitDisabled = !isValid;
	}

	getScanRequestReviewChildComponent(
		scanRequestAction: ScanRequestAction
	): ScanRequestReviewComponent {
		switch (scanRequestAction) {
			case ScanRequestAction.validateOffer:
				return this.validateOfferScanRequest;
			case ScanRequestAction.redeemReward:
				return this.redeemRewardScanRequest;
			case ScanRequestAction.joinMerchant:
				return this.joinMerchantScanRequest;
		}
	}

	dismiss() {
		this.modalController.dismiss();
	}
}
