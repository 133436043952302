import {Component, HostBinding, HostListener, OnInit} from "@angular/core";
import {ActivatedRoute, Router} from "@angular/router";
import {SplashScreen} from "@ionic-native/splash-screen/ngx";
import {StatusBar} from "@ionic-native/status-bar/ngx";
import {Platform} from "@ionic/angular";
import {select, Store} from "@ngrx/store";
import {TranslateService} from "@ngx-translate/core";
import {IBusyConfig} from "ng-busy";
import {combineLatest, EMPTY, Observable} from "rxjs";
import {filter, first, map, switchMap} from "rxjs/operators";
import {AppMode} from "../../../lib/model/app-mode.model";
import {Settings} from "../../../lib/model/settings.model";
import {TermsAgreement} from "../../../lib/model/terms-agreement.model";
import {UserProfile} from "../../../lib/model/user/user-profile.model";
import {AppActions} from "./+state/app.actions";
import {AppLinkService} from "./core/app-links/app-link.service";
import {BusyService} from "./core/busy/busy.service";
import {ConnectionStatus} from "./core/connection-status/connection-status.model";
import {ConnectionStatusService} from "./core/connection-status/connection-status.service";
import {GeolocationService} from "./core/geolocation/geolocation.service";
import {InitRemoteServicesService} from "./core/init-remote-services.service/init-remote-services.service";
import {Logger} from "./core/logger/logger.service";
import {PromiseModalController} from "./core/modal/modal.service";
import {ScanningService} from "./core/scanning/scanning.service";
import {SettingsService} from "./core/settings/settings.service";
import {filterNulls} from "./lib/rxjs-operators/filter-nulls";
import * as fromUser from "./lib/user/+state";
import {UserActions} from "./lib/user/+state/user.actions";
import {AccountModal} from "./lib/user/account/account.modal";
import {MovebeState} from "./movebe-state.model";
import {TermsModal} from "./shared/terms-and-conditions/terms.modal";

@Component({
	selector: "app-root",
	templateUrl: "app.component.html",
})
export class AppComponent implements OnInit {
	isProduction: boolean;

	readonly isUserSignedIn$: Observable<boolean>;
	readonly settings$: Observable<Settings | null>;
	readonly busy$: Observable<IBusyConfig>;
	readonly userProfile$: Observable<UserProfile>;
	busy: IBusyConfig;

	@HostBinding("class.mouse-down")
	cursorDown = false;
	@HostBinding("class.hidden")
	appHidden = false;

	constructor(
		private logger: Logger,
		private appLinkService: AppLinkService,
		private busyService: BusyService,
		private connectionStatusService: ConnectionStatusService,
		private initRemoteServicesService: InitRemoteServicesService,
		private geolocationService: GeolocationService,
		private modalCtrl: PromiseModalController,
		private platform: Platform,
		private scanningService: ScanningService,
		private settingsService: SettingsService,
		private splashScreen: SplashScreen,
		private statusBar: StatusBar,
		private store: Store<MovebeState>,
		private translate: TranslateService,
		private router: Router,
		private route: ActivatedRoute
	) {
		this.settings$ = this.settingsService.getSettings();
		this.busy$ = this.busyService.busy$;
		this.userProfile$ = this.store.pipe(
			select(fromUser.getUserProfile),
			filterNulls()
		);
		this.isUserSignedIn$ = this.store.pipe(select(fromUser.getIsUserSignedIn));
		this.initializeApp();
	}

	ngOnInit() {
		this.store.dispatch(AppActions.QueryEmployers());

		this.platform
			.ready()
			.then(() => this.initializeServices())
			.then(() => this.initializeConnectionMonitoring())
			.then(() => this.checkTermsAndConditions());

		this.platform.resume.subscribe(() => {
			this.appLinkService.checkDeepLinks();
		});
	}

	initializeApp() {
		this.platform
			.ready()
			.then(() => {
				this.statusBar.styleDefault();
				this.splashScreen.hide();
			})
			.then(() => this.initializeServices());
	}

	initializeServices(): Promise<any> {
		if (this.platform.is("cordova")) {
			this.statusBar.styleDefault();
			this.splashScreen.hide();
		}
		this.translate.use("en"); //TODO remove hardcoded language
		const loading = this.initRemoteServicesService.init().then(() => {
			this.appLinkService.checkDeepLinks();
		});
		this.busyService.setBusy(loading);
		return loading;
	}

	checkTermsAndConditions(): Promise<any> {
		return combineLatest(
			this.settings$.pipe(
				map(settings => settings.termsAndConditionsVersions.consumer)
			),
			this.userProfile$.pipe(
				filter(userProfile => userProfile.appMode !== AppMode.init),
				map(userProfile => userProfile.termsAgreements || [])
			)
		)
			.pipe(
				first(),
				switchMap(
					([requiredVersion, acceptedAgreements]: [number, TermsAgreement[]]) =>
						acceptedAgreements.some(
							(acceptedAgreement: TermsAgreement) =>
								acceptedAgreement.type === "consumer" &&
								Math.trunc(acceptedAgreement.version) ===
									Math.trunc(requiredVersion)
						)
							? EMPTY
							: this.ensureAcceptConsumerTerms(
									acceptedAgreements,
									requiredVersion
							  )
				)
			)
			.toPromise();
	}

	ensureAcceptConsumerTerms(
		acceptedAgreements,
		requiredVersion
	): Promise<void | never> {
		return this.modalCtrl
			.presentModal(TermsModal, {termsTemplate: "consumer"})
			.then(didAgree => {
				if (didAgree) {
					acceptedAgreements.push({
						date: new Date(),
						type: "consumer",
						version: requiredVersion,
					});
					return this.store.dispatch(
						UserActions.UpdateUserProfile({
							termsAgreements: acceptedAgreements,
						})
					);
				} else {
					return this.ensureAcceptConsumerTerms(
						acceptedAgreements,
						requiredVersion
					);
				}
			});
	}

	initializeConnectionMonitoring() {
		this.connectionStatusService.disconnectAlert$.subscribe(disconnectAlert =>
			this.busyService.setBusy(
				disconnectAlert.connectionStatusEndedPromise,
				this.translate.instant(
					disconnectAlert.connectionStatus ===
					ConnectionStatus.noFirebaseConnection
						? "BUSY.WAITING_TO_CONNECT_TO_MOVEBE"
						: "BUSY.WAITING_FOR_INTERNET_CONNECTION"
				)
			)
		);
		return Promise.resolve();
	}

	@HostListener("mousedown")
	mouseDown() {
		this.cursorDown = true;
	}

	@HostListener("mouseup")
	mouseUp() {
		this.cursorDown = false;
	}

	openAccountPage() {
		this.modalCtrl.presentModal(AccountModal);
	}
}
