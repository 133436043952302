import {Component} from "@angular/core";
import {ModalController} from "@ionic/angular";
import {select, Store} from "@ngrx/store";
import {Observable} from "rxjs";
import * as fromApp from "../../+state";
import {Employer} from "../../../../../lib/model/employment-relationship.model";
import {MerchantStatus} from "../../../../../lib/model/merchant.model";
import * as fromMerchant from "../../app-modes/merchant/+state";
import {Logger} from "../../core/logger/logger.service";
import {MerchantsService} from "../../core/merchants/merchants.service";
import {CurrentUserService} from "../../core/user/current-user.service";
import {MerchantMembershipActions} from "../../lib/merchant-membership/+state/merchant-membership.actions";
import {JoinExistingMerchantModal} from "../../lib/merchant-membership/join-existing-merchant/join-existing-merchant.modal";
import {UserActions} from "../../lib/user/+state/user.actions";
import {MovebeState} from "../../movebe-state.model";

@Component({
	selector: "page-mm-choose-merchant-modal",
	styleUrls: ["./merchant-chooser.modal.scss"],
	templateUrl: "./merchant-chooser.modal.html",
})
export class MerchantChooserModal {
	readonly currentMerchantId$: Observable<string | undefined>;
	readonly employers$: Observable<Employer[]>;
	readonly MerchantStatus = MerchantStatus;

	constructor(
		private currentUserService: CurrentUserService,
		private logger: Logger,
		private merchantsService: MerchantsService,
		private store: Store<MovebeState>,
		private modalController: ModalController
	) {
		this.employers$ = this.store.pipe(select(fromApp.getEmployers));
		this.currentMerchantId$ = this.store.pipe(
			select(fromMerchant.getCurrentMerchantId)
		);
	}

	cancel() {
		this.modalController.dismiss();
	}

	getStatusIcon(merchantStatus) {
		return this.merchantsService.getStatusIcon(merchantStatus);
	}

	joinExistingMerchant() {
		this.modalController
			.create({component: JoinExistingMerchantModal})
			.then(modal => modal.present());
	}

	registerNewMerchant() {
		this.store.dispatch(MerchantMembershipActions.BecomeMerchant());
	}

	selectMerchant(employer: Employer) {
		this.store.dispatch(UserActions.SelectCurrentMerchant(employer));
		this.cancel();
	}
}
