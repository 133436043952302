import {Injectable} from "@angular/core";
import {Geolocation} from "@ionic-native/geolocation/ngx";
import {Storage} from "@ionic/storage";
import {concat, from, Observable} from "rxjs";
import {filter, map, shareReplay} from "rxjs/operators";
import {LatLngLiteral} from "../../../../../lib/model/mapping/lat-lng-literal.model";
import {FirebaseService} from "../firebase/firebase.service";
import {Logger} from "../logger/logger.service";

@Injectable()
export class GeolocationService {
	readonly geoPositionStorageKey = "GeoPosition";
	readonly currentLatLng$: Observable<LatLngLiteral>;
	readonly currentOrCachedLatLng$: Observable<LatLngLiteral | null>;

	currentLatLng: LatLngLiteral;

	constructor(
		private fb: FirebaseService,
		private geolocation: Geolocation,
		private logger: Logger,
		private storage: Storage
	) {
		const cachedLatLng$: Observable<LatLngLiteral | null> = from(
			this.storage
				.ready()
				.then(() =>
					this.storage.get(this.geoPositionStorageKey)
				) as Promise<LatLngLiteral | null>
		);

		this.currentLatLng$ = this.geolocation.watchPosition().pipe(
			filter(geoposition => !!geoposition.coords), //ignore PositionError results
			map(geoposition => ({
				lat: geoposition.coords.latitude,
				lng: geoposition.coords.longitude,
			})),
			shareReplay(1)
		);

		this.currentOrCachedLatLng$ = concat(cachedLatLng$, this.currentLatLng$);

		this.currentLatLng$.subscribe(latLng => {
			this.currentLatLng = latLng;
			this.storage
				.ready()
				.then(() => this.storage.set(this.geoPositionStorageKey, latLng));
		});
	}
}
