import {Injectable} from "@angular/core";
import {NativeAudio} from "@ionic-native/native-audio/ngx";
import {Vibration} from "@ionic-native/vibration/ngx";
import {Platform} from "@ionic/angular";
import {Logger} from "../logger/logger.service";

@Injectable()
export class EffectsService {
	browserAudio: {
		approve: HTMLAudioElement;
		offerClick: HTMLAudioElement;
		reward: HTMLAudioElement;
		scanSuccessful: HTMLAudioElement;
	};
	readonly halfSecond = 500;
	readonly quarterSecond = 250;

	constructor(
		private nativeAudio: NativeAudio,
		private logger: Logger,
		private platform: Platform,
		private vibration: Vibration
	) {
		this.init();
	}

	approve() {
		try {
			if (this.platform.is("cordova")) {
				this.vibration.vibrate(this.quarterSecond);
				this.nativeAudio.play("approve");
			} else {
				this.browserAudio.approve.play();
			}
		} catch (e) {
			this.logger.error(e);
		}
	}

	offerClick() {
		try {
			if (this.platform.is("cordova")) {
				this.vibration.vibrate(this.quarterSecond);
				this.nativeAudio.play("offerClick");
			} else {
				this.browserAudio.offerClick.play();
			}
		} catch (e) {
			this.logger.error(e);
		}
	}

	reward() {
		try {
			if (this.platform.is("cordova")) {
				setTimeout(() => {
					this.vibration.vibrate(this.quarterSecond);
					this.nativeAudio.play("reward");
				}, this.halfSecond);
			} else {
				this.browserAudio.reward.play();
			}
		} catch (e) {
			this.logger.error(e);
		}
	}

	scanSuccessful() {
		try {
			if (this.platform.is("cordova")) {
				this.vibration.vibrate(this.quarterSecond);
				this.nativeAudio.play("reward");
			} else {
				this.browserAudio.scanSuccessful.play();
			}
		} catch (e) {
			this.logger.error(e);
		}
	}

	private init() {
		if (this.platform.is("cordova")) {
			this.nativeAudio.preloadSimple("reward", "assets/sounds/reward.mp3");
			this.nativeAudio.preloadSimple("approve", "assets/sounds/approve.mp3");
			this.nativeAudio.preloadSimple(
				"offerClick",
				"assets/sounds/offer-click.mp3"
			);
			this.nativeAudio.preloadSimple(
				"scanSuccessful",
				"assets/sounds/scan-successful.mp3"
			);
		} else {
			this.browserAudio = {
				approve: new Audio("assets/sounds/approve.mp3"),
				offerClick: new Audio("assets/sounds/offer-click.mp3"),
				reward: new Audio("assets/sounds/reward.mp3"),
				scanSuccessful: new Audio("assets/sounds/scan-successful.mp3"),
			};
		}
	}
}
