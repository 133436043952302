import {Inject, Injectable} from "@angular/core";
import {FirebaseApp} from "@angular/fire";
import {
	AngularFireDatabase,
	AngularFireList,
	AngularFireObject,
	QueryFn,
	SnapshotAction,
} from "@angular/fire/database";
import * as firebase from "firebase/app";
import "firebase/storage";
import {mimeTypes} from "mime-wrapper";
import * as randomString from "randomstring";
/*tslint:disable:member-ordering*/
/*tslint:disable:no-unsafe-any*/
import {Observable, throwError as observableThrowError} from "rxjs";
import {first, map, shareReplay, switchMap} from "rxjs/operators";
import * as slug from "slug";
import {FirebaseEntity} from "../../../../../lib/model/firebase-entity.model";
import {MerchantLocation} from "../../../../../lib/model/merchant-location.model";
import {PermitTag} from "../../../../../lib/model/permit-tag.model";
import {Provider} from "../../../../../lib/model/provider.model";
import {GeoSearch} from "../../lib/geo-search/geo-search";
import {GeoSearchCriteria} from "../../lib/geo-search/geo-search-criteria.model";
import {GeoSearchMapFunction} from "../../lib/geo-search/geo-search-map-function.model";
import {Logger} from "../logger/logger.service";
import {FloorPlan} from "../movebe-markers/floor-plan.model";
import {
	FloorPlanPlacement,
	MarkerCodeGeotag,
} from "../movebe-markers/marker-code-geotag.model";
import {MarkerDescriptor} from "../movebe-markers/marker-descriptor.model";
import {MarkerScan} from "../movebe-markers/marker-scan.model";
import {MerchantLocationOffers} from "../offer-search/merchant-location-offers.model";
import {ScanRequestResponse} from "../scan-request/scan-request-params.model";
import {
	ScanRequest,
	ScanRequestStatus,
} from "../scan-request/scan-request.model";
import {UserInvitation} from "../user/user-invitation.model";
import {FirestoreService} from "./firestore.service";

@Injectable()
export class FirebaseService {
	dbRoot: firebase.database.Reference;
	private storageRoot: firebase.storage.Reference;

	constructor(
		private logger: Logger,
		private afdb: AngularFireDatabase,
		private firestore: FirestoreService,
		@Inject(FirebaseApp) private firebaseApp: firebase.app.App
	) {}

	generateId(name: string) {
		const suffixLength = 5;
		const suffix = randomString.generate(suffixLength);
		return slug(`${name} ${suffix}`, {lower: true});
	}

	init(): Promise<any> {
		this.dbRoot = this.firebaseApp.database().ref("/");
		this.storageRoot = this.firebaseApp.storage().ref("/");
		return Promise.resolve();
	}

	private get merchantRoot(): firebase.database.Reference {
		return this.dbRoot.child("merchant");
	}

	private get markersRoot(): firebase.database.Reference {
		return this.dbRoot.child("markers");
	}

	private get parkingLotRoot(): firebase.database.Reference {
		return this.dbRoot.child("parking-lot");
	}

	private get userRoot(): firebase.database.Reference {
		return this.dbRoot.child("user");
	}

	private get scanningRoot(): firebase.database.Reference {
		return this.dbRoot.child("scan-request");
	}

	private get providersRoot(): firebase.database.Reference {
		return this.dbRoot.child("provider");
	}

	private get permitTagsRoot(): firebase.database.Reference {
		return this.dbRoot.child("permit-tags");
	}

	private get benefitRoot(): firebase.database.Reference {
		return this.dbRoot.child("benefit");
	}

	private getFirebaseList<T>(
		path: firebase.database.Reference,
		queryFn?: QueryFn
	): AngularFireList<T> {
		return this.afdb.list<T>(path, queryFn);
	}

	private geoFireQuery<T>(
		path: firebase.database.Reference,
		queryCriteria: GeoSearchCriteria,
		mapFunction: GeoSearchMapFunction<T>
	): GeoSearch<T> {
		return new GeoSearch<T>(path, queryCriteria, mapFunction);
	}

	private getFirebaseObject<T>(
		path: firebase.database.Reference
	): AngularFireObject<T> {
		return this.afdb.object<T>(path);
	}

	toObjectStream<T extends FirebaseEntity>(
		afo: AngularFireObject<T>
	): Observable<T | null> {
		return afo.snapshotChanges().pipe(
			map((action: SnapshotAction<T>) => {
				if (!action.payload.exists()) {
					return null;
				}
				return Object.defineProperty(
					{$key: action.key, ...(action.payload.val() as any)},
					"$key",
					{enumerable: false}
				);
			}),
			shareReplay(1)
		); //make the observable hot
	}

	toListStream<T extends FirebaseEntity>(
		afo: AngularFireList<T>
	): Observable<T[]> {
		return afo.snapshotChanges().pipe(
			map(actions => {
				return actions.map((action: SnapshotAction<T>) => {
					return Object.defineProperty(
						{$key: action.key, ...(action.payload.val() as any)},
						"$key",
						{
							enumerable: false,
						}
					);
				});
			}),
			shareReplay(1)
		); //make the observable hot
	}

	private getStorageReference(path: string): firebase.storage.Reference {
		return this.storageRoot.child(path);
	}

	getProvider(methodId: string): AngularFireObject<Provider> {
		return this.getFirebaseObject(this.providersRoot.child(methodId));
	}

	getFirebaseIsConnected(): AngularFireObject<boolean> {
		return this.getFirebaseObject(
			this.firebaseApp.database().ref(".info/connected")
		);
	}

	//region Merchants

	searchMerchantLocations(
		queryCriteria: GeoSearchCriteria,
		mapFunction: GeoSearchMapFunction<MerchantLocationOffers>
	): GeoSearch<MerchantLocationOffers> {
		return this.geoFireQuery<MerchantLocationOffers>(
			this.dbRoot.child("geofire/merchant-location"),
			queryCriteria,
			mapFunction
		);
	}

	//endregion

	//region Parking Lots

	getMarkerDescriptors(locationId: string): AngularFireList<MarkerDescriptor> {
		return this.getFirebaseList<MarkerDescriptor>(
			this.merchantRoot.child(`marker-descriptors/${locationId}`)
		);
	}

	getFloorPlans(
		merchantId: string,
		locationId: string
	): AngularFireList<FloorPlan> {
		return this.getFirebaseList<FloorPlan>(
			this.parkingLotRoot.child(`floor-plans/${merchantId}/${locationId}`)
		);
	}

	getFloorPlan(
		merchantId: string,
		locationId: string,
		floorPlanId: string
	): AngularFireObject<FloorPlan> {
		return this.getFirebaseObject<FloorPlan>(
			this.parkingLotRoot.child(
				`floor-plans/${merchantId}/${locationId}/${floorPlanId}`
			)
		);
	}

	uploadFloorPlan(
		merchantId,
		locationId: string,
		name: string,
		img: Blob
	): firebase.storage.UploadTask {
		const fileExtension = mimeTypes.getExtension(img.type);
		const floorPlanId = this.generateId(name);
		const floorPlanFilename = `${floorPlanId}.${fileExtension}`;
		this.parkingLotRoot
			.child(`floor-plans/${merchantId}/${locationId}/${floorPlanId}`)
			.set({name, fileName: floorPlanFilename});
		return this.getStorageReference(
			`floor-plans/${merchantId}/${locationId}/${floorPlanFilename}`
		).put(img);
	}

	addMarkerDescriptor(locationId: string, descriptorName: string) {
		const descriptorId = slug(descriptorName, {lower: true});
		this.merchantRoot
			.child(`marker-descriptors/${locationId}/${descriptorId}/name`)
			.set(descriptorName);
	}

	deleteMarkerDescriptor(locationId: string, descriptorId: string) {
		this.merchantRoot
			.child(`marker-descriptors/${locationId}/${descriptorId}`)
			.remove();
	}

	addMarkerDescriptorValue(locationId, descriptorId: string, value: string) {
		const valueId = slug(value, {lower: true});
		this.merchantRoot
			.child(
				`marker-descriptors/${locationId}/${descriptorId}/values/${valueId}`
			)
			.set(value);
	}

	deleteMarkerDescriptorValue(
		locationId: string,
		descriptorId: string,
		valueId: string
	) {
		this.merchantRoot
			.child(
				`marker-descriptors/${locationId}/${descriptorId}/values/${valueId}`
			)
			.remove();
	}

	getMarkerCodeGeotags(locationId: string): AngularFireList<MarkerCodeGeotag> {
		return this.getFirebaseList<MarkerCodeGeotag>(this.markersRoot, ref =>
			ref.orderByChild("locationId").equalTo(locationId)
		);
	}

	addMarkerCode(merchantId: string, locationId: string, markerCode: string) {
		//TODO convert to firebase multipath update
		Promise.all([
			this.markersRoot.child(`${markerCode.toLowerCase()}`).set({
				code: markerCode.toUpperCase(),
				locationId,
				merchantId,
			}),
			this.merchantRoot
				.child(`marker-codes/${locationId}/${markerCode.toLowerCase()}`)
				.set(true),
		]);
	}

	getMarkerData(
		markerCode: string
	): Observable<{
		merchantLocation: MerchantLocation;
		markerData: MarkerCodeGeotag;
	}> {
		return this.toObjectStream(
			this.getFirebaseObject(this.markersRoot.child(markerCode.toLowerCase()))
		).pipe(
			switchMap((markerData: MarkerCodeGeotag) => {
				return markerData
					? this.firestore
							.toObjectStream(
								this.firestore.getMerchantLocation(
									markerData.merchantId,
									markerData.locationId
								)
							)
							.pipe(
								map((merchantLocation: MerchantLocation) => {
									return {
										markerData,
										merchantLocation,
									};
								})
							)
					: observableThrowError("No data for this marker code");
			})
		);
	}

	logUserScannedMarker(markerCode: string, userId: string): Promise<string> {
		return this.userRoot
			.child(`${userId}/markerScans`)
			.push({
				markerCode,
				timestamp: new Date().toISOString(),
			})
			.then((r: firebase.database.ThenableReference) => r.key) as Promise<
			string
		>;
	}

	getMarkerScan(scanId: string, userId: string): Observable<any> {
		return this.toObjectStream(
			this.getFirebaseObject<MarkerScan>(
				this.userRoot.child(`${userId}/markerScans/${scanId}`)
			)
		).pipe(
			switchMap((scan: MarkerScan) => {
				return this.getMarkerData(scan.markerCode).pipe(
					map(markerData => {
						return {
							markerCode: scan.markerCode,
							markerData,
							scanId,
							timestamp: scan.timestamp,
						};
					})
				);
			})
		);
	}

	getRecentMarkerScans(userId: string): AngularFireList<MarkerScan> {
		return this.getFirebaseList<MarkerScan>(
			this.userRoot.child(`${userId}/markerScans`)
		);
	}

	setMarkerCodeDescriptorValue(
		locationId: string,
		markerCode: string,
		descriptor: string,
		value: string
	) {
		this.markersRoot
			.child(`${markerCode.toLowerCase()}/descriptors/${descriptor}`)
			.set(value);
	}

	setFloorPlanPlacement(
		markerCodeId: string,
		floorPlanPlacement: FloorPlanPlacement
	) {
		this.markersRoot
			.child(`${markerCodeId}/floorPlanPlacement`)
			.set(floorPlanPlacement);
	}

	//endregion

	// region Users

	pushUserInvitation(merchantId: string, userInvitation: UserInvitation): void {
		this.merchantRoot
			.child(`user-invitations/${merchantId}`)
			.push(userInvitation);
	}

	addUpdateUserInvitation(
		merchantId: string,
		userId: string,
		userInvitation: UserInvitation
	): void {
		this.merchantRoot
			.child(`user-invitations/${merchantId}/${userId}`)
			.set(userInvitation);
	}

	getMerchantUsers(merchantId: string): AngularFireList<any> {
		return this.getFirebaseList<any>(
			this.merchantRoot.child(`users/${merchantId}`)
		);
	}

	getMerchantUserInvitations(merchantId: string): AngularFireList<any> {
		return this.getFirebaseList<any>(
			this.merchantRoot.child(`user-invitations/${merchantId}`)
		);
	}

	//endregion

	//region Scanning

	getScanRequest(requestId: string): AngularFireObject<ScanRequest> {
		return this.getFirebaseObject<ScanRequest>(
			this.scanningRoot.child(`${requestId}`)
		);
	}

	getScanRequests(count: number): AngularFireList<ScanRequest> {
		return this.getFirebaseList<ScanRequest>(this.scanningRoot, ref =>
			ref.limitToLast(count)
		);
	}

	updateScanRequest(
		requestId: string,
		status: ScanRequestStatus,
		responseData?: ScanRequestResponse
	): Promise<any> {
		return this.scanningRoot.child(`${requestId}`).update({
			status,
			...(responseData ? {responseData} : {}),
		});
	}

	//endregion

	cleanEnv(currentUser: string) {
		return Promise.all([
			this.merchantRoot.remove(),
			this.parkingLotRoot.remove(),
			this.permitTagsRoot.remove(),
			this.providersRoot.remove(),
			this.userRoot.remove(),
		]);
	}

	getPermitTagRef(
		operator: string,
		tagId: string
	): firebase.database.Reference {
		return this.permitTagsRoot.child(`${operator}/${tagId}`);
	}

	issueParkingValidationCode(operator: string, userId: string) {
		return new Promise<any>((resolve, reject) => {
			return this.toListStream(
				this.getFirebaseList(this.permitTagsRoot.child(operator), ref =>
					ref
						.orderByChild("issued")
						.equalTo(null)
						.limitToFirst(1)
				)
			)
				.pipe(first())
				.subscribe((codes: PermitTag[]) => {
					const code = codes[0];
					if (!code) {
						return reject("no code available");
					}
					this.getPermitTagRef(operator, code.$key!)
						.update({
							issued: {
								at: new Date().toISOString(),
								to: userId,
							},
						})
						.catch(error => {
							reject(error);
						});
					return resolve(code);
				});
		});
	}

	addPermitTag(operator: string, permitTag: PermitTag): Promise<any> {
		return this.getPermitTagRef(operator, permitTag.id).set(permitTag);
	}
}
