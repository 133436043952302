import {HttpErrorResponse} from "@angular/common/http";
import {action, payload} from "ts-action";
import {AppMode} from "../../../../lib/model/app-mode.model";
import {Employer} from "../../../../lib/model/employment-relationship.model";

export namespace AppActions {
	export const SelectAppMode = action(
		"[App] Select App Mode",
		payload<AppMode>()
	);

	export const HttpErrorOccurred = action(
		"[App] HTTP Error Occurred",
		payload<HttpErrorResponse>()
	);

	export const QueryEmployers = action(
		"[Associated Merchants] Query Employers"
	);

	export const EmployersReceived = action(
		"[Associated Merchants] Employers Received",
		payload<Employer[]>()
	);

	export const HttpErrorNotified = action("[App] HTTP Error Notified");
}
