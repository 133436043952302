import {Injectable} from "@angular/core";
import {Store} from "@ngrx/store";
import {firestore} from "firebase/app";
import {Observable} from "rxjs";
import {combineLatest} from "rxjs/internal/observable/combineLatest";
import {first, switchMap} from "rxjs/operators";
import {BenefitType} from "../../../../../lib/model/benefit.model";
import {Provider} from "../../../../../lib/model/provider.model";
import {RedemptionType} from "../../../../../lib/model/redemption.model";
import {
	IssuedReward,
	Reward,
	RewardStatus,
} from "../../../../../lib/model/reward.model";
import {filterNulls} from "../../lib/rxjs-operators/filter-nulls";
import * as fromUser from "../../lib/user/+state/index";
import {MovebeState} from "../../movebe-state.model";
import {FirebaseService} from "../firebase/firebase.service";
import {FirestoreService} from "../firebase/firestore.service";
import {GeolocationService} from "../geolocation/geolocation.service";
import {Logger} from "../logger/logger.service";
import {MovebeApiService} from "../movebe-api/movebe-api.service";
import {RewardSearchFilter, RewardSearchParams} from "./reward-search.model";

@Injectable()
export class RewardsService {
	readonly userId$: Observable<string>;

	constructor(
		private fb: FirebaseService,
		private firestoreService: FirestoreService,
		private geolocationService: GeolocationService,
		private logger: Logger,
		private movebeApiService: MovebeApiService,
		private store: Store<MovebeState>
	) {
		this.userId$ = this.store.select(fromUser.getUserId).pipe(filterNulls());
	}

	getReward(rewardId: string): Observable<Reward | null> {
		return this.firestoreService.toObjectStream(
			this.firestoreService.getReward(rewardId)
		);
	}

	getCurrentUserRewards(
		searchParams: RewardSearchParams
	): Observable<Reward[]> {
		return this.userId$.pipe(
			filterNulls(),
			first(),
			switchMap(userId =>
				this.searchRewards({
					...searchParams,
					consumerId: userId,
				})
			)
		);
	}

	searchRewards(searchParams: RewardSearchParams): Observable<Reward[]> {
		return this.firestoreService.toListStream(
			this.firestoreService.getRewards(ref => {
				let q: firestore.Query | firestore.CollectionReference = ref;
				if (searchParams.merchantId) {
					q = q.where("merchant.key", "==", searchParams.merchantId);
				}
				if (searchParams.consumerId) {
					q = q.where("consumerId", "==", searchParams.consumerId);
				}
				if (searchParams.benefitType) {
					q = q.where("benefit.type", "==", searchParams.benefitType);
				}
				if (searchParams.filter) {
					q = q.where(
						"isCurrent",
						"==",
						searchParams.filter === RewardSearchFilter.current
					);
				}
				q = q.orderBy("issuedTimestamp", "desc"); //need to use descending order to get most recent
				if (searchParams.count) {
					q = q.limit(searchParams.count);
				}
				return q;
			})
		);
	}

	insertAllocation(reward: IssuedReward): Promise<any> {
		return combineLatest(
			this.geolocationService.currentOrCachedLatLng$,
			this.userId$
		)
			.pipe(first())
			.toPromise()
			.then(([coordinates, userId]) => {
				const lastValidation =
					reward.validations[reward.validations.length - 1];
				if (reward.benefit.type === BenefitType.coupon) {
					throw new Error("cannot insert allocation for coupon benefit");
				}
				return this.movebeApiService.putAllocation({
					amount: reward.benefit.amount,
					benefitType: reward.benefit.type,
					consumerUserId: userId,
					coordinates,
					currency: reward.benefit.currency,
					locationId: lastValidation.locationId,
					merchantId: reward.merchant.key,
					merchantUserId: lastValidation.agentId,
					rewardExpiry: reward.expires.toDate(),
					rewardId: reward.$key!,
				});
			});
	}

	insertRedemption(rewardId: string, provider?: Provider): Promise<any> {
		return combineLatest(
			this.getReward(rewardId).pipe(filterNulls()),
			this.geolocationService.currentOrCachedLatLng$.pipe(first()).toPromise(),
			this.userId$.pipe(first()).toPromise()
		)
			.pipe(first())
			.toPromise()
			.then(([reward, coordinates, userId]) => {
				const lastValidation =
					reward.validations[reward.validations.length - 1];
				if (reward.benefit.type === BenefitType.coupon) {
					throw new Error("cannot insert redemption for coupon benefit"); //milton: ask me about this.
				}
				return this.movebeApiService.putRedemption({
					amount: reward.benefit.amount,
					benefitType: reward.benefit.type,
					consumerUserId: userId,
					coordinates,
					currency: reward.benefit.currency,
					locationId: lastValidation.locationId,
					merchantId: reward.merchant.key,
					merchantUserId: lastValidation.agentId,
					providerId: provider ? provider.id! : undefined,
					rewardId,
					type: RedemptionType.allocated,
				});
			});
	}

	setRewardRedeemed(reward: Reward, provider?: Provider): Promise<any> {
		//TODO: move this into a transactional update on both Firestore and SQL on the server
		return this.firestoreService
			.getReward(reward.$key!)
			.update({
				isCurrent: false,
				redeemedTimestamp: this.firestoreService.serverTimestamp(),
				status: RewardStatus.redeemed,
			})
			.then(() => {
				if (reward.benefit.type !== BenefitType.coupon) {
					return this.insertRedemption(reward.$key!, provider);
				}
			});
	}

	setRewardSurveyCompleted(rewardId: string) {
		return this.firestoreService.getReward(rewardId).update({
			surveyCompleted: true,
		});
	}
}
