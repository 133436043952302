import {animate, style, transition, trigger} from "@angular/animations";
import {
	Component,
	ElementRef,
	EventEmitter,
	Input,
	OnInit,
	Output,
	Renderer2,
	ViewChild,
} from "@angular/core";
import {Observable} from "rxjs";
import {fromEvent} from "rxjs/internal/observable/fromEvent";
import {first} from "rxjs/internal/operators/first";
import {Point} from "../../../../../lib/model/point.model";

//TODO: Fix math/geometry problems using floor plan images with taller aspect ratios

//tslint:disable no-magic-numbers
@Component({
	animations: [
		trigger("fadeInOut", [
			transition(":enter", [
				// :enter is alias to 'void => *'
				style({opacity: 0}),
				animate(500, style({opacity: 1})),
			]),
			transition(":leave", [
				// :leave is alias to '* => void'
				animate(500, style({opacity: 0})),
			]),
		]),
	],
	selector: "movebe-pinch-zoom",
	styleUrls: ["pinch-zoom.component.scss"],
	templateUrl: "pinch-zoom.component.html",
})
export class PinchZoomComponent implements OnInit {
	@Input()
	imageSrc: string;
	@Input()
	initialPosition: Point;
	@Input()
	maxScale = 10;
	@Input()
	minScale = 1;
	@Input()
	position: Point = {x: 0, y: 0};

	@Output()
	positionChanged = new EventEmitter<Point>();

	dragDistance: Point = {x: 0, y: 0};
	dragging: boolean;
	dragStart: Point;
	initialPinchFingerDistance: number;
	scale = 1;
	zoom = 1;

	readonly animationDurationSeconds = 0.2;

	@ViewChild("zoomImage")
	zoomImage: ElementRef;

	constructor(private renderer: Renderer2) {}

	ngOnInit(): void {
		if (this.initialPosition) {
			fromEvent(this.zoomImage.nativeElement as HTMLElement, "load")
				.pipe(first())
				.subscribe(() => {
					this.setPositionFromCenterCoords(this.initialPosition);
					this.transform();
				});
		}
	}

	//region Start Dragging
	mouseDown(event: MouseEvent) {
		this.startDragging(event.clientX, event.clientY);
	}

	startDragging(x: number, y: number) {
		this.dragStart = {x, y};
		this.dragging = true;
	}

	touchStart(event: TouchEvent) {
		const touches = event.touches;
		if (!this.dragging) {
			this.startDragging(touches[0].clientX, touches[0].clientY);
		}
		if (touches.length === 2) {
			this.initialPinchFingerDistance = this.getFingerDistance(touches);
		}
	}

	//endregion

	//region Dragging

	touchMove(event: TouchEvent) {
		const touches = event.touches;

		if (touches.length === 2) {
			this.zoom =
				this.getFingerDistance(touches) / this.initialPinchFingerDistance;
		}

		this.drag(touches[0].clientX, touches[0].clientY);
	}

	mouseMove(event: MouseEvent) {
		this.drag(event.clientX, event.clientY);
	}

	drag(x: number, y: number) {
		if (this.dragging) {
			this.dragDistance = {
				x: (x - this.dragStart.x) / this.scale,
				y: (y - this.dragStart.y) / this.scale,
			};
			this.transform();
		}
	}

	//endregion

	//region Stop Dragging
	touchEnd(event: TouchEvent) {
		const touches = event.touches;
		this.scale = this.scale * this.zoom;
		this.zoom = 1;
		if (touches.length === 0) {
			this.stopDragging();
		}
	}

	mouseUp(event: MouseEvent) {
		this.stopDragging();
	}

	stopDragging() {
		if (!this.dragging) {
			return;
		}
		this.dragging = false;
		this.position.x += this.dragDistance.x;
		this.position.y += this.dragDistance.y;
		this.dragDistance = {x: 0, y: 0};
		this.transform();
		if (this.imageIsOutOfBounds) {
			this.bringImageIntoBounds();
		}
		this.positionChanged.emit(this.centerCoords);
	}

	get imageIsOutOfBounds(): boolean {
		return (
			Math.abs(this.position.x) > this.maxX || // too far to the left or right
			Math.abs(this.position.y) > this.maxY || //too high or too low
			this.scale < this.minScale || //too small
			this.scale > this.maxScale
		); //too big
	}

	bringImageIntoBounds() {
		this.position.x = Math.min(
			this.maxX,
			Math.max(-1 * this.maxX, this.position.x)
		);
		this.position.y = Math.min(
			this.maxY,
			Math.max(-1 * this.maxY, this.position.y)
		);
		this.scale = Math.min(this.maxScale, Math.max(this.minScale, this.scale));
		this.transform(this.animationDurationSeconds);
	}

	//endregion

	rangeScaleChanged() {
		this.transform();
	}

	transform(duration = 0) {
		const easeTransition = duration ? `all ${duration}s ease` : "";
		const transform = `scale(${this.scale * this.zoom}) translate(${
			this.translateX
		}px, ${this.translateY}px)`;
		this.renderer.setStyle(
			this.zoomImage.nativeElement,
			"transition",
			easeTransition
		);
		this.renderer.setStyle(
			this.zoomImage.nativeElement,
			"transform",
			transform
		);
	}

	getFingerDistance(touches: TouchList): number {
		const d = Math.sqrt(
			Math.pow(touches[0].clientX - touches[1].clientX, 2) +
				Math.pow(touches[0].clientY - touches[1].clientY, 2)
		);
		return Math.floor(d);
	}

	get imageWidth(): number {
		return (this.zoomImage.nativeElement as HTMLElement).clientWidth;
	}

	get imageHeight(): number {
		return (this.zoomImage.nativeElement as HTMLElement).clientHeight;
	}

	get maxX() {
		return this.imageWidth / 2;
	}

	get maxY() {
		return this.imageHeight / 2;
	}

	get translateX() {
		return this.position.x + this.dragDistance.x;
	}

	get translateY() {
		return this.position.y + this.dragDistance.y;
	}

	get centerCoords(): Point {
		return {
			x: 0.5 - this.position.x / this.imageWidth,
			y: 0.5 - this.position.y / this.imageHeight,
		};
	}

	setPositionFromCenterCoords(centerCoords: Point) {
		this.position = {
			x: (0.5 - centerCoords.x) * this.imageWidth,
			y: (0.5 - centerCoords.y) * this.imageHeight,
		};
	}
}
