import {Component, Input} from "@angular/core";
import {
	ChatMessage,
	MessageType,
} from "../../../../../../lib/model/chat/chat-message.model";

@Component({
	selector: "movebe-chat-bubble",
	styleUrls: ["chat-bubble.scss"],
	templateUrl: "chat-bubble.html",
})
export class ChatBubbleComponent {
	@Input()
	chatMessage: ChatMessage;
	messageType = MessageType;
}
